export interface CourseAnalyticsStateType {
  newUsers: number;
  totalHoursViewed: number;
  averageHoursPerUser: number;
  averageCompletionPercentage: number;
  courseLength: string;
  totalUnits: number;
  totalChapters: number;
  totalUsers: number;
  courseStudentHoursMetricsDtos: any;
  courseName: string;
  description: string;
  instructorName: string;
  filterSize: number;
}

export const CourseAnalyticsState: CourseAnalyticsStateType = {
  newUsers: 0,
  totalHoursViewed: 0,
  averageHoursPerUser: 0,
  averageCompletionPercentage: 0,
  courseLength: '',
  totalUnits: 1,
  totalChapters: 1,
  totalUsers: 0,
  courseStudentHoursMetricsDtos: [],
  courseName: '',
  description: '',
  instructorName: '',
  filterSize: 0,
};
