import { terminology, pluralize } from '@fuse/terminology';

export const STUDENT_SETUP_ROUTE = `/${pluralize(terminology.student.toLowerCase())}`;
export const STUDENT_SETUP_ADD_ROUTE = `${STUDENT_SETUP_ROUTE}/add`;
export const STUDENT_SETUP_LIST_ROUTE = `${STUDENT_SETUP_ROUTE}/list`;
export const STUDENT_SETUP_GRADUATE_LIST_ROUTE = `${STUDENT_SETUP_ROUTE}/graduatelist`;
export const STUDENT_SETUP_DISABLED_LIST_ROUTE = `${STUDENT_SETUP_ROUTE}/disabledlist`;

export const SIS_OVERVIEW_ROUTE = `${STUDENT_SETUP_ROUTE}/sis/overview`;
export const SIS_GRADES_ROUTE = `${STUDENT_SETUP_ROUTE}/sis/grades`;
export const SIS_REPORT_ROUTE = `${STUDENT_SETUP_ROUTE}/grades/report/term/:termId/batch/:batch`;
export const SIS_GRADESHEET_ROUTE = `${STUDENT_SETUP_ROUTE}/grades/report/academicYear/:academicYearId/program/:programId/academicTerm/:academicTermId`;
export const SIS_ATTENDANCE_ROUTE = `${STUDENT_SETUP_ROUTE}/sis/attendance`;
export const SIS_INFORMATION_ROUTE = `${STUDENT_SETUP_ROUTE}/sis/information`;

const setupStudentsRoute = {
  root: STUDENT_SETUP_ROUTE,
  add: STUDENT_SETUP_ADD_ROUTE,
  list: STUDENT_SETUP_LIST_ROUTE,
  graduateList: STUDENT_SETUP_GRADUATE_LIST_ROUTE,
  sisoverview: SIS_OVERVIEW_ROUTE,
  sisgrades: SIS_GRADES_ROUTE,
  sisattendance: SIS_ATTENDANCE_ROUTE,
  sisprofileInformation: SIS_INFORMATION_ROUTE,
  disabledlist: STUDENT_SETUP_DISABLED_LIST_ROUTE,
  sisgradesheets: SIS_GRADESHEET_ROUTE,
};

export default setupStudentsRoute;
