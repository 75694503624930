export interface GlobalProgressState {
  id: string;
  createdDate: string;
  lastModifiedDate: string;
  userId: string;
  coursesEnrolled: Array<string>;
  coursesCompleted: Array<string>;
  coursesInProgress: Array<string>;
  enrollmentRequestedCourses: Array<string>;
  progressTrackers: Array<string>;
  inactiveCourses: Array<string>;
  courseStates: Array<string>;
}

export interface CourseProgressUpdateState {
  courseId: string;
  courseProgressPercentage: string;
}

export const GlobalProgressInitialState: GlobalProgressState = {
  id: '',
  createdDate: '',
  lastModifiedDate: '',
  userId: '',
  coursesEnrolled: [],
  coursesCompleted: [],
  enrollmentRequestedCourses: [],
  coursesInProgress: [],
  progressTrackers: [],
  inactiveCourses: [],
  courseStates: [],
};
