export const STUDENT_INFO = 'STUDENT_INFO';
export const STUDENT_INFO_ERROR = 'STUDENT_INFO_ERROR';

export const MY_ACCOMPLISHMENT = 'MY_ACCOMPLISHMENT';

export interface Student {
  name: string;
  email: string;
  image: string;
  phoneNumbers: PhoneNumber[];
  programName: string;
  batchName: string;
  startDate: string;
  lastloginDate: string;
  status: string;
  modifiedDate: number;
}

export interface PhoneNumber {
  id: string;
  phoneNumber: number;
  dialCode: string;
  phoneCountry: null | string;
}
