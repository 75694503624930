export var AssignmentStatus;
(function (AssignmentStatus) {
    AssignmentStatus["SUBMITTED"] = "Submitted";
    AssignmentStatus["NOT_SUBMITTED"] = "Not Submitted";
    AssignmentStatus["OVERDUE"] = "Overdue";
    AssignmentStatus["GRADED"] = "Graded";
    AssignmentStatus["CHECKED"] = "Checked";
    AssignmentStatus["RETURNED"] = "Returned";
    AssignmentStatus["RESUBMITTED"] = "Re-submitted";
    AssignmentStatus["LATE_SUBMITTED"] = "Late Submitted";
})(AssignmentStatus || (AssignmentStatus = {}));
