export const DEGREE_ROUTE = '/degree';
export const ADD_DEGREE_ROUTE = '/degree/add';
export const LIST_DEGREE_ROUTE = '/degree/list';
export const EDIT_DEGREE_ROUTE = '/degree/:degreeId/edit';

const degreeRoutes = {
  root: DEGREE_ROUTE,
  add: ADD_DEGREE_ROUTE,
  list: LIST_DEGREE_ROUTE,
  edit: EDIT_DEGREE_ROUTE,
};

export default degreeRoutes;
