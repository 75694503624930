import React from 'react';
import PreviewIPYNBFile from '@fuse/ui-service/dist/PreviewIPYNBFile';
import { getAssignmentPreviewFileRequest } from '../services';
import { isFileType, FileType } from '@fuse/utils/dist/fileUtils';
var PreviewNotebookFile = function (props) {
    var fileId = props.fileId, fileName = props.fileName;
    if (!isFileType(fileName, FileType.ipynb)) {
        return null;
    }
    return (React.createElement(PreviewIPYNBFile, { request: getAssignmentPreviewFileRequest(fileId), fileName: fileName, modalProps: { modalSize: 'xl' } }, function (_a) {
        var onPreview = _a.onPreview;
        return (React.createElement("li", { className: "action--item", onClick: onPreview },
            React.createElement("span", { className: "icon-preview icon-preview-increase" })));
    }));
};
export default PreviewNotebookFile;
