import { FSelect, LoaderWrap } from '@fuse/ui-components';
import { showError, showSuccess } from '@fuse/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { approvalHome, pendingDetails } from '../constant';
import { Approval, defaultApproval, getAllApproval, updateStatus } from './../../../services/approval';
interface SelectOptionTypes {
  label: string;
  value: string;
}

const NewApproval: React.FC = () => {
  const [approvalList, setApprovalList] = useState<Approval[]>(defaultApproval);
  const [selectedModule, setSelectedModule] = useState<string>('');
  const [moduleList, setModuleList] = useState<SelectOptionTypes[]>([{ label: '', value: '' }]);
  const [loading, setLoading] = useState<boolean>(false);

  const getApprovals = async () => {
    setLoading(true);
    const response = await getAllApproval();
    setApprovalList(response);
    setLoading(false);
  };
  useEffect(() => {
    getApprovals();
  }, []);

  useEffect(() => {
    setModuleList([
      { label: 'All', value: '' },
      { label: 'Course Academic Terms', value: 'COURSE_GRADEBOOK_ACADEMIC_TERMS' },
      { label: 'Course Grading', value: 'COURSE_GRADEBOOK_GRADING' },
      { label: 'Recording Deletion Requests', value: 'LIVE_CLASS_RECORDING' },
    ]);
  }, []);

  const giveDecision = async (id: string, status: string) => {
    try {
      const response = await updateStatus(id, status);
      if (response?.message) showSuccess(response.message.split(':').pop());
      getApprovals();
    } catch (err) {
      showError(err);
    }
  };

  const handleModuleDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    try {
      setSelectedModule(value);
      setLoading(true);
      const response = await getAllApproval({ approvalRequestModule: value });
      setApprovalList(response);
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="">
        <Col sm="3">
          <div className="form-group d-flex">
            <label className="w-75 mt-2">Filter by modules</label>
            {/* <select className="form-control form-select">
              <option>All</option>
              <option>Select</option>
            </select> */}
            <FSelect
              onInputChange={handleModuleDropdownChange}
              name="moduleType"
              options={moduleList}
              selectedValue={selectedModule || ''}
            />
          </div>
        </Col>
      </Row>
      <LoaderWrap isLoading={loading}>
        {approvalList.length === 0 && <span className="">No Data Found</span>}
        {approvalList &&
          approvalList.map((approval, approvalIndex) => {
            return (
              <div className="px-4">
                <div key={approvalIndex} className="listing  grid__2col shadow-sm rounded mb-4 no-border">
                  <div className="listing--left">
                    <span className="listing--title text-success mb-1">{approval.title}</span>
                    <div className="listing--desc listing--misc mb-0">
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Course:</span>
                        {approval.courseTitle}
                      </span>
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Level:</span>
                        {approval.levelName}
                      </span>
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Semester:</span> {approval.academicTermName}
                      </span>
                      {approval.approvalRequestModule === 'LIVE_CLASS_RECORDING' && (
                        <>
                          <div className="mr-3">
                            <span className="font-weight-semibold mr-1">Recorded date and time:</span>
                            {moment(approval.recordedDate).format('lll')}
                          </div>
                          <div className="mr-3">
                            <span className="font-weight-semibold mr-1">Recorded video:</span>
                            {approval.noOfVideos}
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <span>
                        <span className="font-weight-semibold mr-1">
                          {approval.approvalRequestModule === 'LIVE_CLASS_RECORDING' ? 'REQUESTED BY:' : 'EDITED BY:'}
                        </span>
                        {approval.requestedBy}
                        <span className="listing--small ml-2 helper">
                          {moment(+approval.requestedDate).format('lll')}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="listing--right">
                    <div className="d-flex approval-status">
                      <div>
                        <span className="badges badges-pending mr-2">Pending</span>
                      </div>
                      <div>
                        <Dropdown className="ellipsis-menu">
                          <Dropdown.Toggle as="div" id="dropdown-custom-1">
                            <span className="icon-three-dots"></span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onClick={() => giveDecision(approval.id, 'APPROVED')}>
                              Approve
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => giveDecision(approval.id, 'DECLINED')}>
                              Decline
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="approval-view">
                      <Link to={`${approvalHome}/${approval.id}${pendingDetails}`} className="links links__blue mr-1">
                        View more
                      </Link>
                    </div>
                  </div>

                  <div className="listing--right">
                    {/* <span className="links links__blue mr-1">View more</span> */}
                  </div>
                </div>
              </div>
            );
          })}
      </LoaderWrap>
    </>
  );
};
export default NewApproval;
