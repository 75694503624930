var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
var defaultOptions = {
    responsive: true,
    legend: {
        display: false,
        position: 'top',
        align: 'end',
        labels: {
            boxWidth: 12,
            boxHeight: 12,
        },
    },
    title: {
        display: false,
        text: '',
    },
    cutoutPercentage: '85',
    cutout: 50,
    tooltips: {
        filter: function (tooltipItem) {
            return;
        },
    },
};
var FDoughnut = function (props) {
    var _a = props.data, data = _a === void 0 ? {} : _a, _b = props.options, options = _b === void 0 ? {} : _b, _c = props.plugins, plugins = _c === void 0 ? [] : _c, centerText = props.centerText;
    var setCenterText = function (chart) {
        var width = chart.width, height = chart.height, ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 80).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.fillStyle = '#595959';
        ctx.textBaseline = 'middle';
        var textX = Math.round((width - ctx.measureText(centerText).width) / 2), textY = height / 2;
        ctx.fillText(centerText, textX, textY);
        ctx.save();
    };
    return (React.createElement(Doughnut, { data: data, options: __assign(__assign({}, defaultOptions), options), plugins: __spreadArrays([
            {
                beforeDraw: function (chart) {
                    if (centerText) {
                        setCenterText(chart);
                    }
                },
            },
        ], plugins) }));
};
export default FDoughnut;
