import * as React from 'react';
export var FTable = function (props) {
    var headers = props.headers, tableClassName = props.tableClassName, children = props.children, colWidths = props.colWidths;
    var header = headers.map(function (headerData, index) {
        return React.createElement("th", { key: index }, headerData);
    });
    return (React.createElement("div", { className: "react-bootstrap-table" },
        React.createElement("table", { className: "table table-hover table-condensed table-responsive " + tableClassName },
            React.createElement("colgroup", null, colWidths),
            React.createElement("thead", null,
                React.createElement("tr", null, header)),
            React.createElement("tbody", null, children))));
};
