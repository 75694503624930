var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
var DropdownSelect = function (props) {
    var _a = props.value, value = _a === void 0 ? [] : _a, options = props.options, _b = props.allowSelectAll, allowSelectAll = _b === void 0 ? false : _b, _c = props.selectAllPlaceholder, selectAllPlaceholder = _c === void 0 ? 'All is Selected' : _c, className = props.className;
    var _d = useState(false), isOpen = _d[0], toggleOpen = _d[1];
    var wrapperRef = useRef(null);
    useEffect(function () {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                toggleOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);
    var allOption = {
        label: 'Select All',
        value: '',
    };
    var getAllOptions = function () {
        if (options.length > 0) {
            if (allowSelectAll) {
                return __spreadArrays([allOption], options);
            }
            return options;
        }
        return [];
    };
    var getSelectedOptions = function () {
        if (value.length > 0) {
            if (value.length >= options.length) {
                return selectAllPlaceholder;
            }
            return value.map(function (data) { return data.label; }).toString();
        }
        return selectAllPlaceholder;
    };
    return (React.createElement(Dropdown, { ref1: wrapperRef, isOpen: isOpen, onClose: function () { return toggleOpen(false); }, clearFilter: function () {
            toggleOpen(false);
            props.onChange([]);
        }, value: value, allowSelectAll: allowSelectAll, target: React.createElement("div", { className: className !== null && className !== void 0 ? className : 'btn border dropdownselect__btn', onClick: function () { return toggleOpen(!isOpen); } },
            React.createElement("span", { className: "text-truncate" }, getSelectedOptions()),
            React.createElement("div", null,
                React.createElement(ChevronDown, null))) },
        React.createElement("div", { className: "w-100" },
            React.createElement(Select, __assign({}, props, { options: getAllOptions(), isMulti: true, autoFocus: true, placeholder: "Search...", backspaceRemovesValue: false, controlShouldRenderValue: false, closeMenuOnSelect: false, components: {
                    Option: Option,
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: null,
                }, hideSelectedOptions: false, menuIsOpen: true, tabSelectsValue: false, isClearable: false, onChange: function (selected, event) {
                    if (selected !== null && selected.length > 0) {
                        // if 'select all' option is selected
                        if (selected[selected.length - 1].value === '') {
                            return props.onChange(getAllOptions());
                        }
                        var result = [];
                        if (selected.length === props.options.length) {
                            // if 'select all' option is already selected and uncheck other option
                            if (selected.some(function (option) { return option.value === ''; })) {
                                result = selected.filter(function (option) { return option.value !== ''; });
                                // if all options is selected except 'select all'
                            }
                            else if (event.action === 'select-option') {
                                result = getAllOptions();
                            }
                            return props.onChange(result);
                        }
                    }
                    // if all options are unselect after selection
                    return props.onChange(selected);
                }, allowSelectAll: true })))));
};
export default DropdownSelect;
var Svg = function (p) { return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", focusable: "false", role: "presentation" }, p))); };
var Menu = function (props) {
    // const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return React.createElement("div", __assign({ className: "dropdownselect__menu" }, props));
};
var Blanket = function (props) { return React.createElement("div", __assign({ className: "menu--open" }, props)); };
var ClearAllSelected = function (_a) {
    var clearFilter = _a.clearFilter;
    return (React.createElement("div", { style: { zIndex: 4, position: 'relative' }, onClick: clearFilter }, "Clear selected items"));
};
var Dropdown = function (_a) {
    var children = _a.children, isOpen = _a.isOpen, target = _a.target, onClose = _a.onClose, clearFilter = _a.clearFilter, allowSelectAll = _a.allowSelectAll, value = _a.value, ref1 = _a.ref1;
    return (React.createElement("div", { ref: ref1, className: "dropdownselect" },
        target,
        isOpen && !allowSelectAll && (value === null || value === void 0 ? void 0 : value[0]) && React.createElement(ClearAllSelected, { clearFilter: clearFilter }),
        isOpen && React.createElement(Menu, null, children),
        isOpen && React.createElement(Blanket, { onClick: onClose })));
};
var DropdownIndicator = function () { return (React.createElement("div", { style: { height: 24, width: 32 } },
    React.createElement(Svg, null,
        React.createElement("path", { id: "Path_24010", "data-name": "Path 24010", d: "M23.734,22.448l-5.159-5.159a8.183,8.183,0,1,0-1.286,1.285l5.159,5.159a.909.909,0,0,0,1.286-1.285Zm-11.552-3.9a6.364,6.364,0,1,1,6.364-6.364A6.364,6.364,0,0,1,12.182,18.545Z", transform: "translate(-4 -4)", fill: "#c7c7c7" })))); };
var ChevronDown = function () { return (React.createElement(Svg, { style: { marginRight: -6 } },
    React.createElement("path", { d: "M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z", fill: "currentColor", fillRule: "evenodd" }))); };
var Option = function (props) {
    return (React.createElement("div", null,
        React.createElement(components.Option, __assign({}, props),
            React.createElement("div", { className: "d-flex align-items-center py-1 " },
                React.createElement("input", { type: "checkbox", checked: props.isSelected, onChange: function () { return null; } }),
                ' ',
                React.createElement("label", { className: "ml-2 mb-0" },
                    React.createElement("span", null, props.label))))));
};
