import { FSelect, LoaderWrap } from '@fuse/ui-components';
// import { Link } from 'react-router-dom';
import { showError } from '@fuse/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { approvalHome, historyDetails } from '../constant';
import { Approval, defaultApproval, getAllHistory } from './../../../services/approval';

interface SelectOptionTypes {
  label: string;
  value: string;
}

const History: React.FC = () => {
  const [historyList, setHistoryList] = useState<Approval[]>(defaultApproval);
  // const [selectedDate, setSelectedDate] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<string>('');
  const [moduleList, setModuleList] = useState<SelectOptionTypes[]>([{ label: 'All', value: '' }]);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [statusList, setStatusList] = useState<SelectOptionTypes[]>([{ label: 'All', value: '' }]);

  useEffect(() => {
    setLoading(true);
    const getHistory = async () => {
      const response = await getAllHistory();
      setHistoryList(response);
      setLoading(false);
    };
    getHistory();
  }, []);

  useEffect(() => {
    setModuleList([
      { label: 'All', value: '' },
      { label: 'Course Academic Terms', value: 'COURSE_GRADEBOOK_ACADEMIC_TERMS' },
      { label: 'Course Grading', value: 'COURSE_GRADEBOOK_GRADING' },
      { label: 'Recording Deletion Requests', value: 'LIVE_CLASS_RECORDING' },
    ]);
    setStatusList([
      { label: 'All', value: '' },
      { label: 'Approved', value: 'APPROVED' },
      { label: 'Declined', value: 'DECLINED' },
    ]);
  }, []);

  // const CustomInput = (props: any) => {
  //   return (
  //     <input
  //       style={{
  //         background: '#ffff',
  //       }}
  //       className="form-control"
  //       onClick={props.onClick}
  //       value={props.value}
  //       type="text"
  //       placeholder="Select Date"
  //       readOnly={true}
  //     />
  //   );
  // };

  // const onChangeDate = (date: Date, e: any) => {
  //   props.setFilterValues({
  //     course: selectedCourseId,
  //     date: getCurrentTimezoneStamp(date),
  //     endDate: getCurrentTimezoneStamp(selectedEndDate),
  //   });
  //   setSelectedDate(date);
  // };

  const changeStyle = (value: string) => {
    const style: string[] = ['badges', 'badges-grade', ' mr-2'];
    if (value === 'APPROVED') style.push('badges-grade');
    else style.push('badges-failed-grade');
    return style.join(' ');
  };

  const handleModuleDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    try {
      setLoading(true);
      setSelectedModule(value);
      const response = await getAllHistory({ approvalRequestModule: value, approvalRequestStatus: selectedStatus });
      setHistoryList(response);
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    try {
      setLoading(true);
      setSelectedStatus(value);
      const response = await getAllHistory({ approvalRequestStatus: value, approvalRequestModule: selectedModule });
      setHistoryList(response);
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="">
        {/* <Col sm="3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1" className="border-0 bg-white">
                Select Date
              </InputGroup.Text>
            </InputGroup.Prepend>
            <ReactDatePicker
              className="form-control"
              showTimeSelect={false}
              // includeDates={dateListForInclude}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select date"
              onChange={onChangeDate}
              selected={selectedDate}
              customInput={<CustomInput />}
              minDate={new Date()}
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
            />
          </InputGroup>
        </Col> */}
        <Col sm="3">
          <div className="form-group d-flex">
            <label className="w-75 mt-2">Filter by status</label>
            <FSelect
              onInputChange={handleStatusDropdownChange}
              name="statusType"
              options={statusList}
              selectedValue={selectedStatus || ''}
            />
          </div>
        </Col>

        <Col sm="3">
          <div className="form-group d-flex">
            <label className="w-75 mt-2">Filter by modules</label>
            <FSelect
              onInputChange={handleModuleDropdownChange}
              name="moduleType"
              options={moduleList}
              selectedValue={selectedModule || ''}
            />
          </div>
        </Col>
      </Row>

      <LoaderWrap isLoading={loading}>
        {historyList.length === 0 && <span>No Data Available</span>}
        {historyList &&
          historyList.map((history, historyIndex) => {
            return (
              <div className="px-4">
                <div key={historyIndex} className="listing  grid__2col shadow-sm rounded mb-4 no-border">
                  <div className="listing--left">
                    <span className="listing--title text-success mb-1">{history.title}</span>

                    <div className="listing--desc listing--misc mb-0">
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Course:</span>
                        {history.courseTitle}
                      </span>
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Level:</span>
                        {history.levelName}
                      </span>
                      <span className="text-color-gray">
                        <span className="font-weight-semibold mr-1">Semester:</span>
                        {history.academicTermName}
                      </span>
                      {history.approvalRequestModule === 'LIVE_CLASS_RECORDING' && (
                        <>
                          <div className="mr-3">
                            <span className="font-weight-semibold mr-1">Recorded date and time:</span>
                            {moment(history.recordedDate).format('lll')}
                          </div>
                          <div className="mr-3">
                            <span className="font-weight-semibold mr-1">Recorded video:</span>
                            {history.noOfVideos}
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <span>
                        <span className="font-weight-semibold mr-1">
                          {history.approvalRequestModule === 'LIVE_CLASS_RECORDING' ? 'REQUESTED BY:' : 'EDITED BY:'}
                        </span>
                        {history.requestedBy}
                        <span className="listing--small ml-2 helper">
                          {moment(+history.requestedDate).format('lll')}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="listing--right">
                    <div className="d-flex approval-status">
                      <span className={changeStyle(history.approvalRequestStatus)}>
                        {history.approvalRequestStatus}
                      </span>
                    </div>
                    <div className="history-footer">
                      <div>
                        <span className="mr-2">
                          <span className="font-weight-semibold mr-1">{history.approvalRequestStatus} BY:</span>
                          {history.requestHandledBy ? history.requestHandledBy : '-'}
                          <span className="listing--small ml-2 helper">
                            {moment(+history.requestHandledDate).format('lll')}
                          </span>
                        </span>
                        <Link to={`${approvalHome}/${history.id}${historyDetails}`} className="links links__blue mr-1">
                          View more{' '}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </LoaderWrap>
    </>
  );
};
export default History;
