import { asyncFunc } from '@fuse/services';
export var getAssignmentFileId = function (params) {
    var data = new FormData();
    data.append('multipartFile', params.file);
    data.append('assignmentType', params.assignmentType);
    data.append('fileType', params.fileType);
    data.append('uploadedBy', params.uploadedBy);
    return asyncFunc({
        method: 'POST',
        url: "api/v2/assignments/uploadAssignmentFiles",
        data: data,
    });
};
export var getCodedAssignmentFileDownload = function (fileId) {
    return {
        method: 'GET',
        url: "/api/v2/assignments/download/codedAssignmentFile",
        params: { fileId: fileId },
    };
};
export var getAssignmentPreviewFileRequest = function (assignmentFileId) {
    return {
        method: 'GET',
        url: "/api/v2/school/fileResources/" + assignmentFileId + "/assignmentFilePreview",
    };
};
