var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { terminology, pluralize } from '@fuse/terminology';
import * as SchoolAssignmentRoutes from './SchoolAssignmentRouteConstant';
export var AssignmentRegistryType;
(function (AssignmentRegistryType) {
    AssignmentRegistryType["PAPER_ASSIGNMENT_REGISTRY"] = "PaperAssignments";
    AssignmentRegistryType["CODED_ASSIGNMENT_REGISTRY"] = "CodedAssignments";
    AssignmentRegistryType["PHYSICAL_ASSIGNMENT_REGISTRY"] = "PhysicalAssignments";
    AssignmentRegistryType["PLAGIARISM_REGISTRY"] = "Plagiarism";
})(AssignmentRegistryType || (AssignmentRegistryType = {}));
export var AssignmentTabList = [
    {
        id: 'paper',
        label: terminology.paper + " " + pluralize(terminology.assignment),
        appRegistryValue: AssignmentRegistryType.PAPER_ASSIGNMENT_REGISTRY,
        schoolAssignmentRoute: SchoolAssignmentRoutes.ASSIGNMENT_ROUTE,
        schoolClassroomAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ASSIGNMENT_ROUTE,
        schoolAddAssignmentRoute: SchoolAssignmentRoutes.ADD_PAPER_ASSIGNMENT_ROUTE,
        schoolClassroomAddAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ADD_PAPER_ASSIGNMENT_ROUTE,
        schoolEditAssignmentRoute: SchoolAssignmentRoutes.EDIT_PAPER_ASSIGNMENT_ROUTE,
        schoolClassroomEditAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_EDIT_PAPER_ASSIGNMENT_ROUTE,
        schoolGradesRoute: SchoolAssignmentRoutes.PAPER_ASSIGNMENT_GRADES_ROUTE,
        schoolDetailRoute: SchoolAssignmentRoutes.PAPER_ASSIGNMENT_DETAIL_ROUTE,
        schoolPhysicalGradeRouteWithGrade: '',
        schoolPhysicalGradeRouteWithoutGrade: '',
        schoolDuplicateAssignmentRoute: SchoolAssignmentRoutes.DUPLICATE_PAPER_ASSIGNMENT_ROUTE,
    },
    {
        id: 'coded',
        label: "Coded " + pluralize(terminology.assignment),
        appRegistryValue: AssignmentRegistryType.CODED_ASSIGNMENT_REGISTRY,
        schoolAssignmentRoute: SchoolAssignmentRoutes.ASSIGNMENT_ROUTE,
        schoolClassroomAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ASSIGNMENT_ROUTE,
        schoolAddAssignmentRoute: SchoolAssignmentRoutes.ADD_CODED_ASSIGNMENT_ROUTE,
        schoolClassroomAddAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ADD_CODED_ASSIGNMENT_ROUTE,
        schoolEditAssignmentRoute: SchoolAssignmentRoutes.EDIT_CODED_ASSIGNMENT_ROUTE,
        schoolClassroomEditAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_EDIT_CODED_ASSIGNMENT_ROUTE,
        schoolGradesRoute: SchoolAssignmentRoutes.CODED_ASSIGNMENT_GRADES_ROUTE,
        schoolDetailRoute: SchoolAssignmentRoutes.CODED_ASSIGNMENT_DETAIL_ROUTE,
        schoolPhysicalGradeRouteWithGrade: '',
        schoolPhysicalGradeRouteWithoutGrade: '',
        schoolDuplicateAssignmentRoute: SchoolAssignmentRoutes.DUPLICATE_CODED_ASSIGNMENT_ROUTE,
    },
    {
        id: 'physical',
        label: terminology.physical + " " + pluralize(terminology.assignment),
        appRegistryValue: AssignmentRegistryType.PHYSICAL_ASSIGNMENT_REGISTRY,
        schoolAssignmentRoute: SchoolAssignmentRoutes.ASSIGNMENT_ROUTE,
        schoolClassroomAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ASSIGNMENT_ROUTE,
        schoolAddAssignmentRoute: SchoolAssignmentRoutes.ADD_PHYSICAL_ASSIGNMENT_ROUTE,
        schoolClassroomAddAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_ADD_PHYSICAL_ASSIGNMENT_ROUTE,
        schoolEditAssignmentRoute: SchoolAssignmentRoutes.EDIT_PHYSICAL_ASSIGNMENT_ROUTE,
        schoolClassroomEditAssignmentRoute: SchoolAssignmentRoutes.CLASSROOM_EDIT_PHYSICAL_ASSIGNMENT_ROUTE,
        schoolGradesRoute: SchoolAssignmentRoutes.PHYSICAL_ASSIGNMENT_GRADES_ROUTE,
        schoolDetailRoute: SchoolAssignmentRoutes.PHYSICAL_ASSIGNMENT_DETAIL_ROUTE,
        schoolPhysicalGradeRouteWithGrade: "" + SchoolAssignmentRoutes.PHYSICAL_ASSIGNMENT_GRADES_ROUTE + SchoolAssignmentRoutes.PHYSICAL_WITH_GRADES_SUB_ROUTE,
        schoolPhysicalGradeRouteWithoutGrade: "" + SchoolAssignmentRoutes.PHYSICAL_ASSIGNMENT_GRADES_ROUTE + SchoolAssignmentRoutes.PHYSICAL_WITHOUT_GRADES_SUB_ROUTE,
        schoolDuplicateAssignmentRoute: SchoolAssignmentRoutes.DUPLICATE_PHYSICAL_ASSIGNMENT_ROUTE,
    },
];
export var supportedAssignmentFiles = [
    'pdf',
    'ppt',
    'pptx',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ipynb',
    'txt',
    'jpeg',
    'jpg',
    'png',
];
export var supportedNotebookFiles = ['ipynb', 'py'];
export var supportedNotebookSubmissionFiles = __spreadArrays(supportedNotebookFiles, ['pb', 'pkl']);
export var supportedGraderFiles = __spreadArrays(supportedNotebookFiles);
export var supportedResourceFiles = ['jpg', 'png', 'ipynb', 'pb', 'txt', 'csv', 'pickle', 'py'];
