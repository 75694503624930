import { AnalyticsStoreState } from './state';
import * as Analytics from './types';

export default function reducer(state = AnalyticsStoreState, action: any) {
  switch (action.type) {
    case Analytics.ANALYTICS:
    case Analytics.ANALYTICS_ERROR:
    case Analytics.ANALYTICS_STUDENTS:
      return {
        ...state,
        ...action.payload,
      };

    /*  case Analytics.ANALYTICS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
 */
    /*  case Analytics.ANALYTICS_STUDENTS:
      return {
        ...state,
        ...action.payload,
      }; */

    default:
      return state;
  }
}
