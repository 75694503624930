export const activeEnv = process.env.REACT_APP_API_ENV || process.env.NODE_ENV || 'development';

const config = {
  rest_url: process.env.REACT_APP_REST_URL,
  aihelpdesk_url: process.env.REACT_APP_AIHELPDESK_REST_URL,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirect_url: process.env.REACT_APP_REDIRECT_URL_SCHOOL,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  title: process.env.REACT_APP_TITLE,
  account_url: process.env.REACT_APP_ACCOUNT_URL,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS_SCHOOL_ADMIN,
  websiteDashboard: process.env.REACT_APP_WEBSITE_DASHBOARD_URL,
  classroomRedirect_url: process.env.REACT_APP_CLASSROOM_URL,
  cognitoProjectRegion: process.env.REACT_APP_COGNITO_PROJECT_REGION,
  cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
  cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoRedirectUrl: process.env.REACT_APP_COGNITO_SCHOOL_REDIRECT_URL,
  sentry_url: process.env.REACT_APP_SENTRY_URL,
  package_version: process.env.REACT_APP_VERSION,
  dataDogAppID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  dataDogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  dataDogService: process.env.REACT_APP_DATADOG_SCHOOL_SERVICE,
  pusherKey: process.env.REACT_APP_PUSHER_KEY,
  pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  fuseClassroomReleaseNoteUrl: process.env.REACT_APP_FUSE_CLASSROOM_WEBSITE,
};

if (
  !process.env.REACT_APP_REDIRECT_URL_SCHOOL &&
  !process.env.REACT_APP_AUTH0_DOMAIN &&
  !process.env.REACT_APP_AUTH0_CLIENT_ID &&
  !process.env.REACT_APP_REST_URL &&
  !process.env.REACT_APP_AIHELPDESK_REST_URL &&
  !process.env.REACT_APP_AUTH0_AUDIENCE &&
  !process.env.REACT_APP_TITLE &&
  !process.env.REACT_APP_ACCOUNT_URL &&
  !process.env.REACT_APP_WEBSITE_DASHBOARD_URL &&
  !process.env.REACT_APP_CLASSROOM_URL &&
  !process.env.REACT_APP_SENTRY_URL &&
  !process.env.REACT_APP_PUSHER_KEY &&
  !process.env.REACT_APP_PUSHER_CLUSTER &&
  !process.env.REACT_APP_FIREBASE_API_KEY &&
  !process.env.REACT_APP_FIREBASE_PROJECT_ID &&
  !process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID &&
  !process.env.REACT_APP_FIREBASE_APP_ID &&
  !process.env.REACT_APP_FUSE_CLASSROOM_WEBSITE
) {
  throw new Error(`Cannot find .env.${activeEnv} file or environment values are missing`);
}

export default config;
