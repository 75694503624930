import React from 'react';
export var GradeNumbers = function (props) {
    var gradeLabel = props.gradeLabel, fullMarks = props.fullMarks, totalGrade = props.totalGrade;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            gradeLabel,
            ": "),
        React.createElement("span", { className: "badges badges-grade mb-2 mx-2" },
            totalGrade,
            "/",
            fullMarks)));
};
