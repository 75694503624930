import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IBreadCrumbItem {
  title?: string;
  url: string;
}

interface IBreadCrumbItemsProps {
  items: IBreadCrumbItem[];
}

const BreadCrumbItems = (props: IBreadCrumbItemsProps) => {
  const { items } = props;
  const breadcrumbList = items.map((item: IBreadCrumbItem, index: number) => {
    return (
      <Breadcrumb.Item active={/*item.url ? true : true*/ true} key={index}>
        {item.url ? <Link to={item.url}>{item.title}</Link> : item.title}
      </Breadcrumb.Item>
    );
  });
  return <>{breadcrumbList}</>;
};

export default BreadCrumbItems;
