var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FButton, FModal } from '@fuse/ui-components';
import { asyncPromiseFunc } from '@fuse/services/dist/Axios';
import axios from '@fuse/config/dist/Axios';
import { downloadFile } from '@fuse/utils/dist/fileUtils';
export var useDownloadFile = function () {
    var _a = useState(), _source = _a[0], _setSource = _a[1];
    var _b = useState({
        request: null,
        fileName: '',
        show: false,
        title: 'Downloading File...',
        retry: false,
    }), _modalData = _b[0], _setModalData = _b[1];
    var _c = useState(0), _downloadPercentage = _c[0], _setDownloadPercentage = _c[1];
    var _downloadFile = function (request, fileName, title, disableCancel, onCancel, onSuccess) {
        _setModalData(__assign(__assign({}, _modalData), {
            request: request,
            fileName: fileName,
            show: true,
            title: title || _modalData.title,
            onCancel: onCancel,
            disableCancel: disableCancel,
            retry: false,
            onSuccess: onSuccess,
        }));
    };
    useEffect(function () {
        if (_modalData.request && !_source) {
            _download();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_modalData.request]);
    var _download = function () { return __awaiter(void 0, void 0, void 0, function () {
        var requestObject, CancelToken, source, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestObject = __assign({
                        responseType: 'blob',
                        onDownloadProgress: function (ProgressEvent) {
                            onDownloadStart(ProgressEvent);
                        },
                    }, _modalData.request);
                    if (!_modalData.disableCancel) {
                        CancelToken = axios.CancelToken;
                        source = CancelToken.source();
                        _setSource(source);
                        requestObject = __assign(__assign({}, requestObject), {
                            cancelToken: source.token,
                        });
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, asyncPromiseFunc(requestObject)];
                case 2:
                    res = _a.sent();
                    if (res && res.data) {
                        downloadFile(res.data, _modalData.fileName);
                        _modalData.onSuccess && _modalData.onSuccess();
                        _setModalData(__assign(__assign({}, _modalData), {
                            request: null,
                            fileName: '',
                            show: false,
                            title: 'Downloading File...',
                            onCancel: undefined,
                            disableCancel: false,
                        }));
                        _setSource(null);
                        _setDownloadPercentage(0);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    // to retry download
                    _setSource(null);
                    _setDownloadPercentage(0);
                    if (axios.isCancel(error_1)) {
                        _setModalData(__assign(__assign({}, _modalData), { show: false }));
                    }
                    else {
                        _setModalData(__assign(__assign({}, _modalData), { retry: true }));
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onDownloadStart = function (data) {
        _setDownloadPercentage(_getPercentage(data));
    };
    var _getPercentage = function (data) {
        if (data.total === 0) {
            return 0;
        }
        return Math.round((data.loaded * 100) / data.total);
    };
    var Modal = (React.createElement(FModal, { backdrop: "static", disableCloseButton: true, className: "resource-modal", modal: _modalData.show, toggleModal: function () {
            _setModalData(__assign(__assign({}, _modalData), { show: !_modalData.show }));
        }, title: _modalData.title },
        React.createElement(React.Fragment, null,
            React.createElement(ProgressBar, { variant: _downloadPercentage < 100 ? 'info' : 'success', now: _downloadPercentage, label: _downloadPercentage + "%" }),
            React.createElement("div", { className: "bottom-btn text-right mr-top-40" },
                React.createElement(FButton, { className: "btn btn-sm btn-cancel", label: "CANCEL", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!_source) return [3 /*break*/, 2];
                                    return [4 /*yield*/, _source.cancel('Download cancel.')];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    _setModalData(__assign(__assign({}, _modalData), { show: false }));
                                    _a.label = 3;
                                case 3:
                                    if (_modalData.onCancel) {
                                        _modalData.onCancel();
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); } }),
                _modalData.retry && (React.createElement(FButton, { className: "btn btn-sm btn-green", label: "RETRY", onClick: function () {
                        _setModalData(__assign(__assign({}, _modalData), { retry: false }));
                        _download();
                    } }))))));
    return {
        downloadFile: _downloadFile,
        downloadModal: Modal,
    };
};
