import React from 'react';
import { Breadcrumb, Col, Nav, Row, Tab } from 'react-bootstrap';
import AppNavbar from './../../components/AllMenus/AppNavbar';
import BreadCrumbItems from './../../scenes/myClassroom/commons/BreadCrumbItems';
import History from './History';
import NewApproval from './NewApproval';

const ApprovalSettings: React.FC = () => {
  return (
    <>
      <AppNavbar isHomeNavbar={false} navBarHeader={'Approval'} hideSearchInput={true} />
      <main>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col lg="6" className="mt-0">
              <Breadcrumb as="div" className="text-uppercase h5 my-2 font-weight-semibold">
                <BreadCrumbItems
                  items={[
                    { title: 'HOME', url: '/home' },
                    { title: 'APPROVALS', url: '' },
                  ]}
                />
              </Breadcrumb>
            </Col>
            <Col lg="6" className="text-right"></Col>
          </Row>

          <div className="main-content">
            <Tab.Container id="approvals" defaultActiveKey="new-approval">
              <div className="main-app-tab">
                <div className="fuse-tabs">
                  <Nav variant="tabs" className="align-items-center" as="ul">
                    <Nav.Item as="li">
                      <Nav.Link eventKey="new-approval">New approvals</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="history">History</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="new-approval">{<NewApproval />}</Tab.Pane>
                <Tab.Pane eventKey="history">{<History />}</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </main>
    </>
  );
};

export default ApprovalSettings;
