export var isNotebookFile = function (notebookFile) {
    if (!notebookFile) {
        return false;
    }
    if (notebookFile.originalFilename) {
        return true;
    }
    return false;
};
export var isFile = function (file) {
    if (!file) {
        return false;
    }
    if (file.name) {
        return true;
    }
    return false;
};
export var getFileName = function (notebookFileOrFile) {
    if (!notebookFileOrFile) {
        return '';
    }
    if (isNotebookFile(notebookFileOrFile)) {
        return notebookFileOrFile.originalFilename;
    }
    else {
        return notebookFileOrFile.name;
    }
};
export var getFileId = function (notebookFileOrFile) {
    if (isNotebookFile(notebookFileOrFile)) {
        return notebookFileOrFile.id;
    }
    return '';
};
export var getNotebookFileId = function (notebookFileOrFile) {
    if (!notebookFileOrFile) {
        return null;
    }
    if (isNotebookFile(notebookFileOrFile)) {
        return notebookFileOrFile.id;
    }
    return '';
};
