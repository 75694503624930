export var FormName;
(function (FormName) {
    FormName["ASSIGNMENT_TITLE"] = "assignmentTitle";
    FormName["TOTAL_GRADE"] = "totalGrade";
    FormName["UNGRADED_ASSIGNMENT"] = "ungradedAssignment";
    FormName["DEADLINE"] = "deadLine";
    FormName["DEADLINE_TIME"] = "deadLineTime";
    FormName["SCHEDULED_DATE"] = "scheduledDate";
    FormName["SCHEDULED_TIME"] = "scheduledTime";
    FormName["INSTRUCTIONS"] = "instructions";
    FormName["FILES"] = "files";
    FormName["FILE"] = "file";
    FormName["OLD_FILES"] = "oldFiles";
    FormName["MULTIPLE_COURSE_LIST"] = "multipleCourseList";
    FormName["COURSE_ID"] = "courseId";
    FormName["MODULE_ID"] = "moduleId";
    FormName["UNIT_ID"] = "unitId";
    FormName["CHAPTER_ID"] = "chapterId";
    FormName["MODULE_DETAILS"] = "moduleDetails";
    FormName["COURSE_OPTIONS"] = "courseOptions";
    FormName["MODULE_OPTIONS"] = "moduleOptions";
    FormName["UNIT_OPTIONS"] = "unitOptions";
    FormName["CHAPTER_OPTIONS"] = "chapterOptions";
    FormName["RESOURCE_FILES"] = "resourceFiles";
    FormName["OLD_RESOURCE_FILES"] = "oldResourceFiles";
    FormName["RESOURCE_FILE"] = "resourceFile";
    FormName["ALLOW_STUDENTS_UPLOAD_RESOURCE_FILE"] = "allowStudentsUploadResourceFile";
    FormName["NOTEBOOKS"] = "notebooks";
    FormName["NOTEBOOK_TITLE"] = "notebookTitle";
    FormName["NOTEBOOK_FILE"] = "notebookFile";
    FormName["GRADER_FILE"] = "graderFile";
    FormName["SUBMISSION_FILE"] = "submissionFile";
    FormName["ACADEMIC_YEAR_ID"] = "academicYearId";
    FormName["ACADEMIC_YEAR_OPTIONS"] = "academicYearOptions";
    FormName["COURSE_OPTIONS_GROUP_BY_ACADEMIC_YEAR"] = "courseOptionsGroupByAcademicYear";
    FormName["REMAINING_COURSE_OPTIONS_GROUP_BY_ACADEMIC_YEAR"] = "remainingCourseOptionsGroupByAcademicYear";
    FormName["SELECTED_COURSE_GROUP_BY_ACADEMIC_YEAR"] = "selectedCourseGroupByAcademicYear";
    FormName["GRADEBOOK_OPTIONS"] = "gradebookOptions";
    FormName["ACADEMIC_TERM_COMPONENT_ID"] = "academicTermComponentId";
    FormName["WEIGHTAGE"] = "weightage";
})(FormName || (FormName = {}));
