/**
 * Author: Sujan Shrestha <sujan.shrestha@fusemachines.com>
 * Reducer for posts (comments and minutes)
 */
import * as _ from 'lodash';
import { GROUP, POST } from '../../constants/actions';
import { PostActionType, PostStateType } from './post.d';

const initialState: PostStateType = {
  posts: {},
  comments: {},
  replies: {},
  users: {},
  postIds: [],
  project: {
    projects: {},
    users: {},
    projectIds: [],
    currentProjectId: '',
  },
  group: {
    groups: {},
    totalGroups: 0,
    groupIds: [],
    currentGroup: null,
  },
  hasMoreProjects: true,
  err: '',
};

export default function (state: PostStateType = initialState, action: PostActionType | any): PostStateType {
  switch (action.type) {
    case POST.UPDATE_POSTS:
      const hasMoreProjects = action.hasMore || false;
      const getPageNum = action.pageNum || 1;
      let postIdsData = [];
      if (getPageNum === 1) {
        postIdsData = [...action.payload.result];
      } else {
        postIdsData = [...state.postIds, ...action.payload.result];
      }
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.payload.entities.posts,
        },
        comments: {
          ...state.comments,
          ...(action.payload.entities.comments || {}),
        },
        replies: {
          ...state.replies,
          ...(action.payload.entities.replies || {}),
        },
        postIds: postIdsData,
        hasMoreProjects: hasMoreProjects,
      };

    case POST.ADD_POST:
      return {
        ...state,
        posts: {
          ...action.payload.entities.posts,
          ...state.posts,
        },
        postIds: [action.payload.result, ...state.postIds],
      };

    case POST.UPDATE_POST:
      const comments = state.posts[action.id].comments;
      action.payload.entities.posts[action.id].comments = comments || [];
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.payload.entities.posts,
        },
      };

    case POST.DELETE_POST:
      const post = action.payload.post;

      // Updating Posts
      const updatedPosts = {
        ...state.posts,
      };
      delete updatedPosts[post.id];

      // Updating postIds
      const updatedPostIds = [...state.postIds];
      const index = updatedPostIds.indexOf(post.id);
      if (index > -1) {
        updatedPostIds.splice(index, 1);
      }

      return {
        ...state,
        posts: {
          ...updatedPosts,
        },
        postIds: [...updatedPostIds],
      };

    case POST.ADD_COMMENT: {
      const posts = { ...state.posts };
      const postComments = { ...state.comments, ...action.payload.entities.comments };
      if (action.id) {
        const postComment = posts[action.id].comments || [];
        posts[action.id].comments = [...postComment, action.payload.result[0]];
        posts[action.id].totalComments++;
      }
      return {
        ...state,
        posts: {
          ...state.posts,
          ...posts,
        },
        comments: {
          ...state.comments,
          ...postComments,
        },
      };
    }

    case POST.DELETE_COMMENT: {
      const selectedPost: any = {};
      selectedPost[action.postId] = {
        ...state.posts[action.postId],
        comments: state.posts[action.postId].comments.filter((key: string) => key !== action.commentId),
        totalComments: state.posts[action.postId].totalComments - 1,
      };
      // let filteredComments = _.omit(state.comments, [action.commentId]);
      return {
        ...state,
        posts: {
          ...state.posts,
          ...selectedPost,
        },
        // filteredComments
      };
    }

    case POST.UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          ...action.payload.entities.comments,
        },
      };

    // case POST.PSEUDO_UPDATE_COMMENT:
    //   let post = state.posts[action.postId];
    //   let comment = {};
    //   comment[action.commentId] = action.payload.entities.comments;
    //   return {
    //     ...state,
    //     comments: {
    //       ...state.comments,
    //       ...{}
    //     }

    //   }

    case POST.GET_ALL_COMMENTS: {
      const posts = { ...state.posts };
      const allComments = { ...state.comments, ...action.payload.entities.comments };
      if (action.id) {
        if (action.pageNum === 1) {
          posts[action.id].comments = [...action.payload.result];
        } else {
          posts[action.id].comments = [...action.payload.result, ...state.posts[action.id].comments];
        }
      }
      return {
        ...state,
        posts: {
          ...state.posts,
          ...posts,
        },
        comments: {
          ...state.comments,
          ...allComments,
        },
      };
    }

    case POST.TOGGLE_LIKE: {
      const { postId, userId } = action;
      const selectedPost: any = {};
      selectedPost[postId] = state.posts[postId];
      const indexOfUserId = selectedPost[postId].likedBy.indexOf(userId);
      if (indexOfUserId > -1) {
        selectedPost[postId].likedBy = selectedPost[postId].likedBy.filter((id: string) => id !== userId);
      } else {
        selectedPost[postId].likedBy.push(userId);
      }
      return {
        ...state,
        posts: {
          ...state.posts,
          ...selectedPost,
        },
      };
    }

    case POST.FETCH_POSTS_ERROR:
      const postIds = action.payload.pageNum > 1 ? [...state.postIds] : [];
      return {
        ...state,
        postIds: postIds,
        hasMoreProjects: false,
        err: action.payload.err,
      };

    case POST.CLEAR_ALL:
    case POST.CLEAR_ALL_POSTS:
      return {
        ...initialState,
      };

    case POST.GET_ONE_POST: {
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.payload.entities.posts,
        },
        comments: {
          ...state.comments,
          ...(action.payload.entities.comments || {}),
        },
        replies: {
          ...state.replies,
          ...(action.payload.entities.replies || {}),
        },
        postIds: [...action.payload.result],
        hasMoreProjects: false,
      };
    }
    case POST.FETCH_POSTS_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.data.entities.users,
        },
        project: {
          ...state.project,
          users: {
            ...state.project.users,
            [action.payload.groupId]: [...action.payload.data.result],
          },
        },
        group: {
          ...state.group,
          groups: {
            ...state.group.groups,
            [action.payload.groupId]: {
              ...(state.group.groups[action.payload.groupId] || {}),
              totalMembers: (action.payload.data.result || []).length || 0,
            },
          },
        },
      };
    }

    case POST.FETCH_POSTS_USER_OTHER: {
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.data.entities.users,
        },
      };
    }
    case GROUP.FETCH_ALL_GROUP:
      return {
        ...state,
        group: {
          ...state.group,
          groups: {
            ...state.group.groups,
            ...(action.payload.groups.entities.groups || {}),
          },
          totalGroups: action.payload.otherParams.totalElements,
          groupIds: [...(action.payload.groups.result || [])],
        },
      };
    case GROUP.FETCH_GROUP_BY_ID:
    case GROUP.UPDATE_GROUP:
      return {
        ...state,
        group: {
          ...state.group,
          groups: {
            ...state.group.groups,
            [action.payload.groupId]: { ...action.payload },
          },
        },
      };

    case GROUP.REMOVE_GROUP_MEMBERS_BY_IDS: {
      const updatedUsers = [...state.project.users[action.payload.groupId]];
      _.remove(updatedUsers, function (userId) {
        return action.payload.groupMemberIds.indexOf(userId) > -1;
      });
      return {
        ...state,
        project: {
          ...state.project,
          users: {
            ...state.project.users,
            [action.payload.groupId]: [...updatedUsers],
          },
        },
      };
    }
    case GROUP.ADD_GROUP: {
      return {
        ...state,
        group: {
          ...state.project,
          groups: {
            ...state.group.groups,
            [action.payload.groupId]: action.payload,
          },
          totalGroups: state.group.totalGroups + 1,
          groupIds: [action.payload.groupId, ...state.group.groupIds],
        },
      };
    }

    default:
      return state;
  }
}
