import { ActionTypes, GlobalProgressAction } from './actions';
import { CourseProgressUpdateState, GlobalProgressInitialState, GlobalProgressState } from './state';

export default function reducers(
  state: GlobalProgressState = GlobalProgressInitialState,
  action: GlobalProgressAction,
) {
  switch (action.type) {
    case ActionTypes.GLOBAL_PROGRESS_FETCH:
      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.PROGRESS_PERCENT_UPDATE:
      const newState = action.payload as CourseProgressUpdateState;
      const progress: any = {};
      progress[newState.courseId] = newState.courseProgressPercentage;
      return {
        ...state,
        courseStates: {
          ...state.courseStates,
          ...progress,
        },
      };
    case ActionTypes.UPDATE_LOCAL_DATA:
      const newCourseEnrolled = [...state.coursesEnrolled];
      newCourseEnrolled.push(action.payload);
      return {
        ...state,
        coursesEnrolled: newCourseEnrolled,
      };
    case ActionTypes.GLOBAL_PROGRESS_ERROR:
      return state;

    default:
      return state;
  }
}
