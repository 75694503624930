import { AssignmentType } from '../types';
import { AssignmentTabList } from '../constants/AssignmentUtilConstants';
import { terminology } from '@fuse/terminology';
export var isCodedAssignment = function (assignment) {
    if (assignment && assignment.assignmentType === AssignmentType.CodeAssignment) {
        return true;
    }
    return false;
};
export var getAssignmentTabList = function (appRegistry) {
    return AssignmentTabList.filter(function (assignmentTab) {
        return appRegistry.includes(assignmentTab.appRegistryValue);
    });
};
export var getAssignmentTabById = function (id) {
    return AssignmentTabList.find(function (assignmentTab) { return assignmentTab.id === id; });
};
export var getDefaultAssignmentType = function (appRegistry) {
    var assignmentTabList = getAssignmentTabList(appRegistry);
    if (assignmentTabList && assignmentTabList.length > 0) {
        return assignmentTabList[0].id;
    }
    return '';
};
export var getAssignmentTypeOptions = function (appRegistry) {
    var assignmentTypeOptions = [];
    AssignmentTabList.forEach(function (assignmentTab) {
        if (appRegistry.includes(assignmentTab.appRegistryValue)) {
            assignmentTypeOptions.push({
                label: assignmentTab.label,
                value: assignmentTab.id,
            });
        }
    });
    return assignmentTypeOptions;
};
export var getAcademicYearName = function (academic) {
    var academicYear = academic.academicStartYear === academic.academicEndYear
        ? "" + academic.academicStartYear
        : academic.academicStartYear + " - " + academic.academicEndYear;
    return "" + academicYear;
};
/**
 *
 * @param appRegistries
 * @param assignmentType
 * @returns boolean if registry contains specific assignment type
 */
export var isContainAssignmentType = function (appRegistries, assignmentType) {
    var assignmentTypeAppRegistryValue = AssignmentTabList.find(function (assignmentTab) { return assignmentTab.id === assignmentType; });
    if (assignmentTypeAppRegistryValue) {
        return appRegistries.includes(assignmentTypeAppRegistryValue.appRegistryValue);
    }
    return false;
};
/**
 *
 * @param type
 * @returns if given string is valid assignment type
 */
export var isValidAssignmentType = function (type) {
    return Object.values(AssignmentType).includes(type);
};
export var getAssignmentTypeLabel = function (assignmentType) {
    var _a;
    return ((_a = AssignmentTabList.find(function (assignmentTab) { return assignmentTab.id === assignmentType; })) === null || _a === void 0 ? void 0 : _a.label) || '';
};
export var validateGrade = function (value, allValues) {
    if (!value || (value && value.trim() === '') || isNaN(parseInt(value))) {
        return 'Required';
    }
    if (value && parseInt(value) > allValues.fullMarks) {
        return terminology.grade + " should be less than total " + terminology.grade.toLowerCase() + ".";
    }
    return undefined;
};
