import { asyncPromiseDataFunc } from '@fuse/services';

export interface Approval {
  academicTermId: string;
  academicTermName: string;
  approvalRequestModule: string;
  approvalRequestStatus: string;
  batchId: string;
  courseId: string;
  courseTitle: string;
  id: string;
  levelId: string;
  levelName: string;
  requestHandledBy: string;
  requestHandledDate: string;
  requestedBy: string;
  requestedDate: number;
  title: string;
  recordedDate?: number;
  recordings?: Array<any>;
  noOfVideos?: string;
}

export const defaultApproval: Approval[] = [
  {
    academicTermId: '',
    academicTermName: '',
    approvalRequestModule: '',
    approvalRequestStatus: '',
    batchId: '',
    courseId: '',
    courseTitle: '',
    id: '',
    levelId: '',
    levelName: '',
    requestHandledBy: '',
    requestHandledDate: '',
    requestedBy: '',
    requestedDate: 0,
    title: '',
  },
];

export interface PendingDetails {
  academicTermId: string;
  academicTermName: string;
  approvalRequestModule: string;
  approvalRequestStatus: string;
  batchId: string;
  courseGradeBookStatus: string;
  courseGradebookComparison: boolean;
  courseGradebookRegistryId: string;
  courseId: string;
  courseTitle: string;
  id: string;
  levelId: string;
  levelName: string;
  requestedBy: string;
  requestedDate: number;
  termComponentComparisons?: TermComponentsComparison[];
  gradingTemplateComparison?: GradingTemplateComparison;
  requestHandledDate?: number;
  requestHandledBy?: string;
  recordedDate?: number;
  recordings?: Array<any>;
  requestDescription?: string;
  noOfVideos?: string;
}

interface GradingTemplateComparison {
  // allowDescriptionUpdated: boolean;
  // allowGpaUpdated: boolean;
  gradeScaleTypeUpdated: boolean;
  gradingCriterionComparisons: GradingCriterionComparison[];
  previousAllowDescription: boolean;
  previousAllowGpa: boolean;
  previousGradeScaleType: string;
  updatedAllowDescription: boolean;
  updatedAllowGpa: boolean;
  updatedGradeScaleType: string;

  descriptionUpdated: boolean;
  gpaUpdated: boolean;
  highestPercentageUpdated: boolean;
  letterScaleUpdated: boolean;
  lowestPercentageUpdated: boolean;
}

interface GradingCriterionComparison {
  descriptionUpdated: boolean;
  gpaUpdated: boolean;
  highestPercentageUpdated: boolean;
  letterScaleUpdated: boolean;
  lowestPercentageUpdated: boolean;
  previousDescription: string;
  previousGpa: number;
  previousHighestPercentage: number;
  previousLetterScale: string;
  previousLowestPercentage: number;
  updatedDescription: string;
  updatedGpa: number;
  updatedHighestPercentage: number;
  updatedLetterScale: string;
  updatedLowestPercentage: number;
}

interface TermComponentsComparison {
  academicTermComponentId: string;
  deleted: boolean;
  name: string;
  previousWeightage: number;
  previousWeightageProvided: boolean;
  updatedWeightage: number;
  updatedWeightageProvided: boolean;
  weightageUpdated: boolean;
}

export const defaultPendingDetails: PendingDetails = {
  academicTermId: '',
  academicTermName: '',
  approvalRequestModule: '',
  approvalRequestStatus: '',
  batchId: '',
  courseGradeBookStatus: '',
  courseGradebookComparison: false,
  courseGradebookRegistryId: '',
  courseId: '',
  courseTitle: '',
  id: '',
  levelId: '',
  levelName: '',
  requestedBy: '',
  requestedDate: 0,
  termComponentComparisons: [
    {
      academicTermComponentId: '',
      deleted: false,
      name: '',
      previousWeightage: 0,
      previousWeightageProvided: false,
      updatedWeightage: 0,
      updatedWeightageProvided: false,
      weightageUpdated: false,
    },
  ],
  gradingTemplateComparison: {
    // allowDescriptionUpdated: false,
    // allowGpaUpdated: false,
    gradeScaleTypeUpdated: false,
    gradingCriterionComparisons: [
      {
        descriptionUpdated: false,
        gpaUpdated: false,
        highestPercentageUpdated: false,
        letterScaleUpdated: false,
        lowestPercentageUpdated: false,
        previousDescription: '',
        previousGpa: 0,
        previousHighestPercentage: 0,
        previousLetterScale: '',
        previousLowestPercentage: 0,
        updatedDescription: '',
        updatedGpa: 0,
        updatedHighestPercentage: 0,
        updatedLetterScale: '',
        updatedLowestPercentage: 0,
      },
    ],
    previousAllowDescription: false,
    previousAllowGpa: false,
    previousGradeScaleType: '',
    updatedAllowDescription: false,
    updatedAllowGpa: false,
    updatedGradeScaleType: '',
    descriptionUpdated: false,
    gpaUpdated: false,
    highestPercentageUpdated: false,
    letterScaleUpdated: false,
    lowestPercentageUpdated: false,
  },
  requestHandledDate: 0,
  requestHandledBy: '',
};
export interface IFilters {
  approvalRequestModule?: string;
  approvalRequestStatus?: string;
}

export const getAllApproval = (params?: IFilters) => {
  return asyncPromiseDataFunc<Approval[]>({
    method: 'GET',
    url: `/api/v2/approvalrequests/pending`,
    params,
  });
};

export const getAllHistory = (params?: IFilters) => {
  return asyncPromiseDataFunc({
    method: 'GET',
    url: `/api/v2/approvalrequests/history`,
    params,
  });
};

export const getPendingDetails = (id: string) => {
  return asyncPromiseDataFunc<PendingDetails>({
    method: 'GET',
    url: `/api/v2/approvalrequests/${id}`,
  });
};

export const updateStatus = (approvalRequestId: string, status: string) => {
  return asyncPromiseDataFunc({
    method: 'PUT',
    url: `/api/v2/approvalrequests/${approvalRequestId}/approvalrequeststatus?approvalRequestStatus=${status}`,
  });
};
