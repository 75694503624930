import { ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// Separates string from html string
export var separateStringsFromHtml = function (htmlString) {
    var _a, _b;
    var contentBlock = htmlToDraft(htmlString);
    var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    var data = convertToRaw(contentState);
    return (_b = (_a = data === null || data === void 0 ? void 0 : data.blocks) === null || _a === void 0 ? void 0 : _a.map(function (data) { return data === null || data === void 0 ? void 0 : data.text; })) === null || _b === void 0 ? void 0 : _b.join(' ');
};
