export interface StudentStoreStateType {
  courseStatuses: any;
  coursesCompleted: number;
  coursesEnrolled: number;
  coursesInprogress: number;
  joinDate: string;
  lastLogin: string;
  studentEmail: string;
  studentName: string;

  //accomplishment
  myAccomplishment: any;
}

export const StudentStoreState: StudentStoreStateType = {
  courseStatuses: [],
  coursesCompleted: 0,
  coursesEnrolled: 0,
  coursesInprogress: 0,
  joinDate: '',
  lastLogin: '',
  studentEmail: '',
  studentName: '',

  myAccomplishment: null,
};
