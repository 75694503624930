import * as React from 'react';
import NoInternet from './NoInternet';

interface ILoading {
  loaderClass?: string;
  pastDelay?: boolean | string;
  error?: any;
}

const Loading = (props: ILoading) => {
  if (props.error) {
    return <NoInternet />;
  } else if (props.pastDelay) {
    return (
      <div className={`loader-wrap big-loader ${props.loaderClass}`}>
        <svg
          className="svg-loader"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="100%"
          viewBox="0 0 500 500"
          id="loader"
        >
          <polygon className="segment" points="250,250 250,0 465,126" />
          <polygon className="segment" points="250,250 465,126 465,375" />
          <polygon className="segment" points="250,250 465,375 250,500" />
          <polygon className="segment" points="250,250 250,500 36,375" />
          <polygon className="segment" points="250,250 36,375 36,126" />
          <polygon className="segment" points="250,250 36,126 250,0" />
        </svg>
      </div>
    );
  } else {
    return <div />;
  }
};
export default Loading;
