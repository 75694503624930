export const STAFF_ROUTE = '/staff';
export const STAFF_LIST = '/staff/list';
export const STAFF_ADD = '/staff/add';

const StaffRoutes = {
  root: STAFF_ROUTE,
  add: STAFF_ADD,
  list: STAFF_LIST,
};

export default StaffRoutes;
