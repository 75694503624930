import React from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import AppNavbar from './components/AllMenus/AppNavbar';
import { LoaderWrap } from '@fuse/ui-components/dist/LoaderWrap';
import * as ROLES from './components/PrivateRoute/constants';
import { routeUrl } from './LinkUrl';
import ApprovalSettings from './scenes/Approval';
import ReactGA from 'react-ga';
import NotificationProvider from '@fuse/notification/dist/components/NotificationContextProvider';
import PrivateRoute from '@fuse/cognito/dist/utils/PrivateRoute';
import { ROOT_ROUTE, NOT_FOUND_ROUTE } from '@fuse/cognito/dist/constants';
import { ASSIGNMENT_ROUTE } from '@fuse/assignment';

const AsyncSchoolGlobalSettings = React.lazy(() => import('./scenes/GlobalSettings'));

const AsyncAttendance = React.lazy(() => import('./scenes/Attendance/AssignedCourses'));

const AsyncQuizzes = React.lazy(() => import('./scenes/myClassroom/Assessment/Quizzes'));

const AsyncExams = React.lazy(() => import('./scenes/myClassroom/Assessment/Exams'));

const AsyncApprovalRoutes = React.lazy(() => import('./scenes/Approval/ApprovalRoutes'));
const AsyncGradesheetRoutes = React.lazy(() => import('./scenes/Gradesheet/GradesheetRoutes'));

const AsyncAssignment = React.lazy(() => import('./scenes/myClassroom/Classroom/Assignment/AssignmentRoutes'));
const AsyncGrade = React.lazy(() => import('./scenes/Grades'));
const AsyncGradebook = React.lazy(() => import('./scenes/Gradebook'));

/**
 * myClassroom import
 */
const AsyncLiveClassroom = React.lazy(() => import('./scenes/myClassroom/LiveClass/LiveClassroomRoute'));
const AsyncClassroom = React.lazy(() => import('./scenes/myClassroom/Classroom/ClassroomRoutes'));
const AsyncAnnouncements = React.lazy(() => import('./scenes/myClassroom/Classroom/Announcements'));
const AsyncSetupPrograms = React.lazy(() => import('./scenes/myClassroom/SetupPrograms'));
const AsyncSetupAIHelpdesk = React.lazy(() => import('./scenes/myClassroom/SetupAIHelpdesk'));
const AsyncSetupFacility = React.lazy(() => import('./scenes/myClassroom/SetupFacility'));
const AsyncSetupDegrees = React.lazy(() => import('./scenes/myClassroom/SetupDegrees'));
const AsyncSetupSchools = React.lazy(() => import('./scenes/myClassroom/SetupSchools'));
const AsyncSetupCourseCatalog = React.lazy(() => import('./scenes/myClassroom/CourseCatalog'));
const AsyncSetupStudents = React.lazy(() => import('./scenes/myClassroom/SetupStudents'));
const SetupLiveClasses = React.lazy(() => import('./scenes/myClassroom/SetupLiveClasses'));

const AsyncSetupStaffs = React.lazy(() => import('./scenes/myClassroom/SetupStaffs'));
const AsyncSetupCourses = React.lazy(() => import('./scenes/myClassroom/SetupCourses'));
const AsyncSetupAcademicYear = React.lazy(() => import('./scenes/myClassroom/SetupAcademicYear'));
const Profile = React.lazy(() => import('./scenes/myClassroom/Profile'));
const AsyncCommunityRoute = React.lazy(
  () => import('./scenes/myClassroom/Forum/scenes/CommunityForum/CommunityRoutes'),
);
const AsyncAdmissionForm = React.lazy(() => import('./scenes/myClassroom/AdmissionForm'));
const AdminAnnouncementRoutes = React.lazy(() => import('./scenes/myClassroom/AdminAnnouncements/routes'));

const AsyncAdminGradeBook = React.lazy(() => import('./scenes/myClassroom/GradeBook/adminRoutes'));
const AsyncAnalyticsRoutes = React.lazy(() => import('src/scenes/Analytics/routes'));
const AsyncHome = React.lazy(() => import('./scenes/myClassroom/Home'));
const FilePreview = React.lazy(() => import('./scenes/myClassroom/FilePreview'));
const AsyncAdminNewsFeed = React.lazy(() => import('./scenes/myClassroom/NewsFeed'));

const PrivateRoutes = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.listen((location: any) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }, [history]);

  return (
    <React.Suspense fallback={<LoaderWrap isLoading={true} type="Main" />}>
      <NotificationProvider>
        <Switch>
          <PrivateRoute exact path={routeUrl.classroom.filePreview} component={FilePreview} roles={ROLES.ALL} />
          <PrivateRoute
            path={routeUrl.globalSchoolSettings.home}
            component={AsyncSchoolGlobalSettings}
            roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
          />
          <PrivateRoute exact path={[routeUrl.home, ROOT_ROUTE]} component={AsyncHome} roles={ROLES.ALL} />
          <PrivateRoute path={ROOT_ROUTE} component={RoutesWithNavBar} roles={ROLES.ALL} />
        </Switch>
      </NotificationProvider>
    </React.Suspense>
  );
};

const RoutesWithNavBar = () => {
  return (
    <React.Suspense fallback={<LoaderWrap isLoading={true} type="Main" />}>
      <AppNavbar />
      <Switch>
        {/** myClassroom new Routes */}

        <PrivateRoute
          path={routeUrl.analytics.home}
          component={AsyncAnalyticsRoutes}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute exact path={routeUrl.profile} component={Profile} roles={ROLES.ALL} />

        <PrivateRoute
          exact
          path={routeUrl.approvalSettings.home}
          component={ApprovalSettings}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={`${routeUrl.classInstructor.liveClassroom}`}
          component={AsyncLiveClassroom}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute
          path={`${routeUrl.announcements}`}
          component={AsyncAnnouncements}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        {/** end myClassroom new Routes */}
        <PrivateRoute
          path={`${routeUrl.classroom.home}`}
          component={AsyncClassroom}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute path={ASSIGNMENT_ROUTE} component={AsyncAssignment} roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]} />

        <PrivateRoute
          path={`${routeUrl.gradeBookAdminRoutes.adminGradebook}`}
          component={AsyncAdminGradeBook}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        {/** end myClassroom new Routes */}

        <PrivateRoute
          path={`${routeUrl.classInstructor.attendance.home}`}
          component={AsyncAttendance}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute
          path={`${routeUrl.classInstructor.grade.home}`}
          component={AsyncGrade}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute
          path={`${routeUrl.gradeBookRoutes.home}`}
          component={AsyncGradebook}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute
          path={`${routeUrl.quizzes.home}`}
          component={AsyncQuizzes}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute path={`${routeUrl.exams.list}`} component={AsyncExams} roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]} />

        <PrivateRoute
          path={`${routeUrl.gradeSheet}`}
          component={AsyncGradesheetRoutes}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={`${routeUrl.approvalSettings.home + routeUrl.approvalSettings.approval}`}
          component={AsyncApprovalRoutes}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={`${routeUrl.approvalSettings.home + routeUrl.approvalSettings.history}`}
          component={AsyncApprovalRoutes}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={`${routeUrl.adminAnnouncements}`}
          component={AdminAnnouncementRoutes}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={`${routeUrl.community.communityHome}`}
          component={AsyncCommunityRoute}
          roles={[ROLES.ACADEMY_CLASS_INSTRUCTOR]}
        />

        <PrivateRoute
          path={routeUrl.setupPrograms.root}
          component={AsyncSetupPrograms}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={routeUrl.setupAIHelpdesk.home}
          component={AsyncSetupAIHelpdesk}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={routeUrl.setupFacility.root}
          component={AsyncSetupFacility}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupDegrees.root}
          component={AsyncSetupDegrees}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupSchools.root}
          component={AsyncSetupSchools}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupStaffs.root}
          component={AsyncSetupStaffs}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupCourseCatalog.root}
          component={AsyncSetupCourseCatalog}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupStudents.root}
          component={AsyncSetupStudents}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setUpLiveClassesRoutes.root}
          component={SetupLiveClasses}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />

        <PrivateRoute
          path={routeUrl.setupCourses.home}
          component={AsyncSetupCourses}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={routeUrl.setupAcademicYear.root}
          component={AsyncSetupAcademicYear}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR]}
        />
        <PrivateRoute
          path={routeUrl.admissionForm.root}
          component={AsyncAdmissionForm}
          roles={[ROLES.ACADEMY_PROGRAM_MODERATOR, ROLES.CO_ADMIN]}
        />

        <PrivateRoute path={routeUrl.newsFeed} component={AsyncAdminNewsFeed} roles={ROLES.ALL} />

        <Redirect to={NOT_FOUND_ROUTE} />
      </Switch>
    </React.Suspense>
  );
};

export default PrivateRoutes;
