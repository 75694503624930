export const ADMISSION_ROUTE = '/admission';
export const ADMISSION_LIST_ROUTE = '/admission/list';
export const SUBMISSION_LIST_ROUTE = '/admission/submission/:admissionFormId/viewlist';
export const SUBMISSION_PREVIEW_ROUTE = '/admission/submission/:admissionFormId/preview';
export const EXAM_SCHEDULE_PREVIEW_ROUTE = '/admission/:admissionFormId/:programId/exam-schedule';
export const EXAM_SCHEDULE_EDIT_ROUTE = '/admission/:admissionFormId/:programId/exam-schedule/edit';
export const SIGNED_UP_APPLICANT_LIST_ROUTE = '/admission/applicants/:programId/:admissionFormId/list';

const admissionRoutes = {
  root: ADMISSION_ROUTE,
  list: ADMISSION_LIST_ROUTE,
  submissionlist: SUBMISSION_LIST_ROUTE,
  submissionpreview: SUBMISSION_PREVIEW_ROUTE,
  examSchedulePreview: EXAM_SCHEDULE_PREVIEW_ROUTE,
  examScheduleEdit: EXAM_SCHEDULE_EDIT_ROUTE,
  applicantList: SIGNED_UP_APPLICANT_LIST_ROUTE,
};

export default admissionRoutes;
