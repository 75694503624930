//instructor dashboard
export const ANALYTICS = 'FETCH_ANALYTICS';
export const UPDATE_NOTIFICATION = 'UPDATE_ANALYTICS';
export const DELETE_NOTIFICATION = 'DELETE_ANALYTICS';

//student dashboard
export const ANALYTICS_STUDENTS = 'ANALYTICS_STUDENTS';

//defaults
export const ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';
export const DEFAULT = 'DEFAULT';
