export var AssignmentType;
(function (AssignmentType) {
    AssignmentType["PaperAssignment"] = "paper";
    AssignmentType["CodeAssignment"] = "coded";
    AssignmentType["PhysicalAssignment"] = "physical";
})(AssignmentType || (AssignmentType = {}));
export var AssignmentSubType;
(function (AssignmentSubType) {
    AssignmentSubType["open"] = "open";
    AssignmentSubType["grade"] = "grade";
})(AssignmentSubType || (AssignmentSubType = {}));
export var AssignmentFileType;
(function (AssignmentFileType) {
    AssignmentFileType["Resource"] = "Resource";
    AssignmentFileType["Notebook"] = "Notebook";
    AssignmentFileType["Grader"] = "Grader";
    AssignmentFileType["Submission"] = "Submission";
})(AssignmentFileType || (AssignmentFileType = {}));
export var AssignmentUploadedBy;
(function (AssignmentUploadedBy) {
    AssignmentUploadedBy["Instructor"] = "Instructor";
    AssignmentUploadedBy["Student"] = "Student";
})(AssignmentUploadedBy || (AssignmentUploadedBy = {}));
