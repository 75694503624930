var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
import TextEditor from '../TextEditor';
export var FinalTextArea = function (props) {
    var legend = props.legend, name = props.name, formProps = props.formProps, required = props.required, validate = props.validate, value = props.value;
    var isRequired = function (value) { return (value && value.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { name: name, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var _b;
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: "form-group " + (meta.error && meta.touched ? 'error' : '') },
            React.createElement("label", { htmlFor: "" },
                legend,
                (required || validate) && legend ? React.createElement("span", { className: "astrik" }, "*") : ''),
            React.createElement("div", null,
                React.createElement(TextEditor, __assign({}, input, { change: formProps.form.change, value: value ? value : ((_b = formProps === null || formProps === void 0 ? void 0 : formProps.values) === null || _b === void 0 ? void 0 : _b[name]) ? formProps.values[name] : '', fieldName: name })),
                meta.error && meta.touched && React.createElement("span", { className: "required" }, meta.error))));
    }));
};
// export default FinalTextArea;
