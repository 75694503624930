import { terminology, pluralize } from '@fuse/terminology';
var classroom = "/" + terminology.classroom.toLowerCase().replace(' ', '-');
var assignment = terminology.assignment.toLowerCase();
var grade = terminology.gradeTitle.toLowerCase();
export var ASSIGNMENT_ROUTE = "/" + assignment;
export var CLASSROOM_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/" + assignment;
export var DUPLICATE_PAPER_ASSIGNMENT_ROUTE = '/assignment/:assignmentId/paper/duplicate';
export var DUPLICATE_CODED_ASSIGNMENT_ROUTE = '/assignment/:assignmentId/coded/duplicate';
export var DUPLICATE_PHYSICAL_ASSIGNMENT_ROUTE = '/assignment/:assignmentId/physical/duplicate';
export var ADD_PAPER_ASSIGNMENT_ROUTE = "/" + assignment + "/paper/add";
export var ADD_CODED_ASSIGNMENT_ROUTE = "/" + assignment + "/coded/add";
export var ADD_PHYSICAL_ASSIGNMENT_ROUTE = "/" + assignment + "/physical/add";
export var CLASSROOM_ADD_PAPER_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/" + assignment + "/paper/add";
export var CLASSROOM_ADD_CODED_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/" + assignment + "/coded/add";
export var CLASSROOM_ADD_PHYSICAL_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/assignment/physical/add";
export var EDIT_PAPER_ASSIGNMENT_ROUTE = "/" + assignment + "/:assignmentId/paper/edit";
export var EDIT_CODED_ASSIGNMENT_ROUTE = "/" + assignment + "/:assignmentId/coded/edit";
export var EDIT_PHYSICAL_ASSIGNMENT_ROUTE = "/" + assignment + "/:assignmentId/physical/edit";
export var CLASSROOM_EDIT_PAPER_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/" + assignment + "/:assignmentId/paper/edit";
export var CLASSROOM_EDIT_CODED_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/" + assignment + "/:assignmentId/coded/edit";
export var CLASSROOM_EDIT_PHYSICAL_ASSIGNMENT_ROUTE = classroom + "/course/:courseId/assignment/:assignmentId/physical/edit";
export var CODED_ASSIGNMENT_GRADES_ROUTE = "/" + assignment + "/:assignmentId/coded/" + pluralize(grade);
export var CODED_ASSIGNMENT_DETAIL_ROUTE = "/" + assignment + "/:assignmentId/coded/detail";
export var PAPER_ASSIGNMENT_GRADES_ROUTE = "/" + assignment + "/:assignmentId/paper/" + pluralize(grade);
export var PAPER_ASSIGNMENT_DETAIL_ROUTE = "/" + assignment + "/:assignmentId/paper/detail";
export var PAPER_ASSIGNMENT_SUBMISSION_ROUTE = "/" + assignment + "/:assignmentId/paper/submission";
export var PHYSICAL_ASSIGNMENT_GRADES_ROUTE = "/" + assignment + "/:assignmentId/physical/" + pluralize(grade);
export var PHYSICAL_ASSIGNMENT_DETAIL_ROUTE = "/" + assignment + "/:assignmentId/physical/detail";
export var OPEN_ASSIGNMENT_PLAGIARISM_ROUTE = "/" + assignment + "/:assignmentId/plagiarism";
export var PAPER_ASSIGNMENT_SUBMISSION_PLAGIARISM_ROUTE = "/" + assignment + "/:assignmentId/paper-assignment-submission";
export var PHYSICAL_WITH_GRADES_SUB_ROUTE = "/" + grade;
export var PHYSICAL_WITHOUT_GRADES_SUB_ROUTE = "/open";
