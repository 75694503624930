var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import firebase from '@firebase/app';
import '@firebase/messaging';
import config from '../env';
import { addOrReplaceToken } from '@fuse/services/dist/Notification';
import { SetStorageComponent, GetStorageComponent } from '@fuse/utils/dist/session';
import { FIREBASE_TOKEN } from '@fuse/messages/dist/common';
var Firebase = /** @class */ (function () {
    function Firebase() {
        this.messaging = null;
        if (firebase.messaging.isSupported() && !firebase.apps.length) {
            try {
                var firebaseInstance = firebase.initializeApp({
                    apiKey: config.firebaseApiKey,
                    projectId: config.firebaseProjectId,
                    messagingSenderId: config.firebaseMessagingSenderId,
                    appId: config.firebaseAppId,
                });
                this.messaging = firebaseInstance.messaging();
            }
            catch (error) {
                console.log("FIREBASE :: " + error);
            }
        }
        else {
            this.messaging = firebase.app().messaging();
        }
    }
    Firebase.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var oldToken, token, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isRequestPermissionGranted()) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        oldToken = GetStorageComponent(FIREBASE_TOKEN) || '';
                        return [4 /*yield*/, this.getToken()];
                    case 2:
                        token = _a.sent();
                        if (!(oldToken !== token)) return [3 /*break*/, 4];
                        return [4 /*yield*/, addOrReplaceToken(token, oldToken)];
                    case 3:
                        _a.sent();
                        this.setToken(token);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.log('Unable to get permission to notify.', error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    Firebase.prototype.isRequestPermissionGranted = function () {
        return this.messaging
            .requestPermission()
            .then(function () { return true; })
            .catch(function (err) {
            console.log('Unable to get permission to notify.', err);
            return false;
        });
    };
    Firebase.prototype.getToken = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.messaging
                .getToken()
                .then(function (currentToken) {
                if (currentToken) {
                    resolve(currentToken);
                }
                else {
                    reject('No Instance ID token available. Request permission to generate one.');
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    Firebase.prototype.setToken = function (token) {
        SetStorageComponent(FIREBASE_TOKEN, token);
    };
    return Firebase;
}());
export default Firebase;
