import { pluralize, terminology } from '@fuse/terminology';
import { IOptions } from '@fuse/ui-components';

export const ADMIN_ANNOUNCEMENT_ROUTE = '/admin-announcements';
export const ADMIN_PUBLISH_ANNOUNCEMENT_ROUTE = '/admin-announcements/publish';
export const ADMIN_DRAFT_ANNOUNCEMENT_ROUTE = '/admin-announcements/draft';

export const GENERAL_ANNOUNCEMENT = 'General_Announcement';

export type ANNOUNCEMENT_RECEIVER = 'EVERYONE' | 'STUDENT' | 'INSTRUCTOR';

export const RECEIVER_OPTIONS: IOptions[] = [
  {
    label: `All`,
    value: 'EVERYONE',
  },
  { label: `All ${pluralize(terminology.student)}`, value: 'STUDENT' },
  { label: `All ${pluralize(terminology.instructor)}`, value: 'INSTRUCTOR' },
  { label: `All ${pluralize('parent')}`, value: 'PARENT' },
  { label: `All Students and Parents`, value: 'PARENT_AND_STUDENT' },
  { label: `All Students and Instructors`, value: 'INSTRUCTOR_AND_STUDENT' },
];
