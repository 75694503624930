var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
var FHorizontalBar = function (props) {
    var _a = props.data, data = _a === void 0 ? {} : _a, _b = props.options, options = _b === void 0 ? {} : _b, _c = props.plugins, plugins = _c === void 0 ? [] : _c, _d = props.postfixXAxesLabel, postfixXAxesLabel = _d === void 0 ? '' : _d, _e = props.dynamicHeight, dynamicHeight = _e === void 0 ? true : _e;
    var defaultOptions = {
        maintainAspectRatio: true,
        layout: {
            padding: {
                right: 80,
            },
        },
        responsive: true,
        legend: {
            position: 'top',
            align: 'end',
            labels: {
                boxWidth: 12,
                boxHeight: 12,
            },
        },
        title: {
            display: false,
            text: '',
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        callback: function (value, index, values) {
                            return value + " " + postfixXAxesLabel;
                        },
                        max: 100,
                    },
                    gridLines: { display: true },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        // Set Course name to maximum 3 line with 30 characters per line
                        callback: function (tooltipItem) {
                            return tooltipItem
                                .replace(/.{30}\S*\s+/g, '$&@')
                                .split(/\s+@/)
                                .slice(0, 3);
                        },
                    },
                    gridLines: { display: false },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataSet = data.datasets[tooltipItem.datasetIndex];
                    var datasetLabel = dataSet.label;
                    var customDataSet = dataSet[datasetLabel.toLowerCase().replace(/ /g, '_')];
                    if (customDataSet) {
                        var xLabel = customDataSet[tooltipItem.index];
                        return " " + datasetLabel + " : " + xLabel;
                    }
                    return datasetLabel;
                },
            },
        },
    };
    //For background color of download charts//
    var backgroundColor = function (chart) {
        var ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    };
    var zeroCompensation = {
        renderZeroCompensation: function (chartInstance, model) {
            var context = chartInstance.chart.ctx;
            // the model.y is the central point of the bar, so we need minus half width of the bar.
            var startY = model.y - model.height / 2;
            // common canvas API, Check it out on MDN
            context.beginPath();
            // set line color, you can do more custom settings here.
            context.strokeStyle = '#aaaaaa';
            context.lineWidth = 3;
            context.moveTo(model.x, startY, model.y);
            // draw the line
            context.lineTo(model.x, startY + model.height);
            // you will see the lines.
            context.stroke();
        },
        renderNumber: function (chartInstance, model, data, number) {
            var ctx = chartInstance.ctx;
            ctx.font = 1 + 'em sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            var labelData = typeof data === 'number' ? data : '';
            if (labelData !== 0) {
                labelData = labelData + " %";
            }
            ctx.fillText(" " + labelData, model.x + 25, model.y + 8);
        },
        afterDatasetsDraw: function (chart, easing) {
            var _this = this;
            var datasets = chart.config.data.datasets;
            datasets.forEach(function (dataset, datasetIndex) {
                dataset.data.forEach(function (data, dataIndex) {
                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[dataIndex]._model;
                    var isLegendHidden = chart.legend.legendItems[datasetIndex].hidden;
                    // for custom label ( number percentage)
                    var number;
                    var customDataset = dataset[dataset.label.toLowerCase().replace(/ /g, '_')];
                    if (customDataset) {
                        number = customDataset[dataIndex];
                    }
                    if (typeof isLegendHidden === 'boolean' && !isLegendHidden) {
                        // to show gray line for data === 0
                        if (data === 0) {
                            _this.renderZeroCompensation(chart, model);
                        }
                        _this.renderNumber(chart, model, data, number);
                    }
                });
            });
        },
    };
    var getDynamicHeight = function () {
        if (dynamicHeight) {
            var height = (data === null || data === void 0 ? void 0 : data.labels.length) >= 0 ? data.labels.length * 40 : 50;
            if (height <= 50) {
                return 60;
            }
            return height;
        }
        return undefined;
    };
    return (React.createElement(HorizontalBar, { data: data, options: __assign(__assign({}, defaultOptions), options), plugins: __spreadArrays([
            {
                beforeDraw: function (chart) {
                    backgroundColor(chart);
                },
            },
        ], [zeroCompensation], plugins), height: getDynamicHeight() }));
};
export default FHorizontalBar;
