import {
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  ROOT_ROUTE,
} from '@fuse/cognito/dist/constants';
import PrivateRoute from '@fuse/cognito/dist/utils/PrivateRoute';
import PublicRoute from '@fuse/cognito/dist/utils/PublicRoute';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loading from './components/Loading';
import * as ROLES from './components/PrivateRoute/constants';
import { routeUrl } from './LinkUrl';
import PrivateRoutes from './PrivateRoutes';

const ErrorPage = React.lazy(() => import('./scenes/404'));
const Login = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.Login })));
const Logout = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.Logout })));
const ForgotPassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ForgotPassword })));
const ChangePassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ChangePassword })));

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading pastDelay={true} />}>
        <Switch>
          <Route exact path={routeUrl.notFound} component={ErrorPage} />

          <PublicRoute exact path={LOGIN_ROUTE} component={Login} />
          <PublicRoute exact path={LOGOUT_ROUTE} component={Logout} />
          <PublicRoute exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
          <PublicRoute exact path={CHANGE_PASSWORD_ROUTE} component={ChangePassword} />

          {/* -------------- ********* authenticated routes *********  -------------- */}

          <PrivateRoute path={ROOT_ROUTE} component={PrivateRoutes} roles={ROLES.ALL} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default MainRoutes;
