var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { Line } from 'react-chartjs-2';
var LineChart = function (props) {
    var data = props.data, _a = props.options, options = _a === void 0 ? {} : _a, _b = props.plugins, plugins = _b === void 0 ? [] : _b, _c = props.postfixYAxesLabel, postfixYAxesLabel = _c === void 0 ? '' : _c;
    var defaultOptions = {
        responsive: true,
        legend: {
            position: 'top',
            align: 'end',
            labels: {
                boxWidth: 12,
                boxHeight: 12,
            },
        },
        title: {
            display: false,
            text: '',
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return value + " " + postfixYAxesLabel;
                        },
                        stepSize: 10,
                        max: 100,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: { display: false },
                },
            ],
        },
    };
    //For background color of download charts//
    var backgroundColor = function (chart) {
        var ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    };
    return (React.createElement(Line, { data: data, options: __assign(__assign({}, defaultOptions), options), plugins: __spreadArrays([
            {
                beforeDraw: function (chart) {
                    backgroundColor(chart);
                },
            },
        ], plugins) }));
};
export default LineChart;
