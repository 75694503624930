var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
export var FMultiSelect = function (props) {
    var name = props.name, placeholder = props.placeholder, selectedValue = props.selectedValue, options = props.options, className = props.className, isLoading = props.isLoading, isDisabled = props.isDisabled, isClearable = props.isClearable, isSearchable = props.isSearchable, legend = props.legend, required = props.required, formProps = props.formProps, validate = props.validate;
    return (React.createElement(Field, { name: name, type: "checkbox", validate: validate ? validate : undefined }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: "form-group " + (meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                required && legend ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement(Select, __assign({}, input, { className: className, placeholder: placeholder, value: selectedValue, 
                //@ts-ignore
                onChange: function (value) {
                    console.log('changes', name, value);
                    formProps.form.change(name, value);
                }, isLoading: isLoading, isDisabled: isDisabled, isClearable: isClearable, options: options, defaultValue: selectedValue, isSearchable: isSearchable, isMulti: true })),
            meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? (React.createElement("span", { className: "required" }, meta.error)) : null));
    }));
};
