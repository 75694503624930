var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var normalizeData = function (data, key) {
    var normalData = {};
    data.forEach(function (object) {
        var _a;
        normalData = __assign(__assign({}, normalData), (_a = {}, _a[object[key || '']] = object, _a));
    });
    return normalData;
};
