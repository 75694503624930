import { ThunkAction } from 'redux-thunk';
// import { GlobalProgressState, CourseProgressUpdateState } from './state';
import { showError } from '../../utils/alertNotification';
import axios from '@fuse/config/dist/Axios';
import { GetStorageComponent } from '@fuse/utils/dist/session';

export enum ActionTypes {
  GLOBAL_PROGRESS_FETCH = 'GLOBAL_PROGRESS_FETCH',
  GLOBAL_PROGRESS_ERROR = 'GLOBAL_PROGRESS_ERROR',
  PROGRESS_PERCENT_UPDATE = 'PROGRESS_PERCENT_UPDATE',
  UPDATE_LOCAL_DATA = 'UPDATE_LOCAL_DATA',
  DEFAULT = 'DEFAULT',
}

export interface GlobalProgressAction {
  type: ActionTypes;
  payload: any;
  // payload: CourseProgressUpdateState | GlobalProgressState ;
}

export function fetchGlobalProgress(callBack?: () => void): ThunkAction<void, void, void, any> {
  return dispatch => {
    const accessToken = GetStorageComponent('access_token');
    const idToken = GetStorageComponent('id_token');
    return axios
      .request({
        method: 'GET',
        url: `/global`,
        headers: {
          Authorization: 'bearer ' + accessToken,
          idToken: idToken,
        },
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: ActionTypes.GLOBAL_PROGRESS_FETCH,
            payload: response.data,
          });
          if (callBack) {
            callBack();
          }
          // return response;
        }
      })
      .catch(error => {
        /* dispatch({
        type: ActionTypes.GLOBAL_PROGRESS_ERROR,
        payload: error.data
      }); */
        if (error.data) {
          showError(error.data.message);
        }
      });
  };
}

export function updateProgressPercent(
  courseId: string,
  courseProgressPercentage: string,
): ThunkAction<void, void, void, any> {
  return dispatch => {
    dispatch({
      type: ActionTypes.PROGRESS_PERCENT_UPDATE,
      payload: {
        courseId,
        courseProgressPercentage,
      },
    });
  };
}
