var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { asyncPromiseDataFunc, axiosPromiseFunction, asyncFunc } from '../Axios';
import { GetStorageComponent } from '@fuse/utils/dist/session';
import { FIREBASE_TOKEN } from '@fuse/messages/dist/common';
export var addOrReplaceToken = function (token, oldToken) {
    return asyncPromiseDataFunc({
        method: 'POST',
        url: "/notification/token?token=" + token + "&oldToken=" + oldToken,
    });
};
export var removeNotificationToken = function () { return __awaiter(void 0, void 0, void 0, function () {
    var notificationToken;
    return __generator(this, function (_a) {
        notificationToken = GetStorageComponent(FIREBASE_TOKEN);
        if (notificationToken) {
            return [2 /*return*/, deleteNotificationToken(notificationToken)];
        }
        return [2 /*return*/];
    });
}); };
export var deleteNotificationToken = function (token) {
    return asyncPromiseDataFunc({
        method: 'DELETE',
        url: "/notification/token?token=" + token,
    });
};
export var getAllMessage = function (params) {
    return axiosPromiseFunction({
        method: 'GET',
        url: "/notification/message/all",
        params: params,
    });
};
export var getMessageById = function (messageId) {
    return axiosPromiseFunction({
        method: 'GET',
        url: "/notification/message/" + messageId,
    });
};
export var markAllAsRead = function () {
    return axiosPromiseFunction({
        method: 'POST',
        url: "/notification/message/all/read",
    });
};
export var markMessageAsRead = function (messageId) {
    return axiosPromiseFunction({
        method: 'POST',
        url: "/notification/message/" + messageId + "/read",
    });
};
export var getAnnouncementById = function (messageId) {
    return axiosPromiseFunction({
        method: 'GET',
        url: "/announcements/messages/" + messageId + "/students",
    });
};
export var getNotificationCount = function () {
    return asyncFunc({
        method: 'GET',
        url: "/notification/badge",
    });
};
export var pairConfirmReject = function (data) {
    return asyncFunc({
        method: 'POST',
        url: "/api/v2/parent/approval",
        data: data,
    });
};
