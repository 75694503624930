import * as createHistory from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';

/**
 * Creates a history object that uses the HTML5 history API including
 * pushState, replaceState, and the popstate event.
 *
 */
const history = createHistory.createBrowserHistory();

export { history };

// Build the middleware for intercepting and dispatching navigation actions
const historyMiddleware = routerMiddleware(history);
const disableLoggers = ['production', 'development', 'staging'];
const env = process.env.REACT_APP_API_ENV || 'development';
const middlewares = disableLoggers.includes(env)
  ? applyMiddleware(thunk, historyMiddleware)
  : composeWithDevTools(applyMiddleware(thunk, logger, historyMiddleware));
const Store = createStore(reducers, middlewares);

export default Store;
