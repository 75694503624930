import * as React from 'react';
import './noInternet.css';

const NoInternet = (prop: any) => {
  return (
    <div className="wifi-wrap">
      <div className="wifi-symbol">
        <div className="wifi-circle first"></div>
        <div className="wifi-circle second"></div>
        <div className="wifi-circle third"></div>
        <div className="wifi-circle fourth"></div>
        <div className="mark"></div>
      </div>
      <div className="wifi-text">Please make sure you are connected to the internet!</div>
    </div>
  );
};

export default NoInternet;
