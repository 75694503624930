const home = `/gradebook`;
const setup = `${home}/setup`;
const setupItems = `${setup}/items`;
const gradeScale = `/gradeScale`;
const gradeItems = '/gradebook/gradeItems/setup';
const viewDetails = `/viewDetails/studentID=:studentId`;
const adminGradebook = `/admin/gradebook`;
const adminGradebookSetup = `${adminGradebook}/setup`;
const adminGradebookSetupDetails = `${adminGradebookSetup}/details`;
const adminGradebookSetupTerms = `${adminGradebookSetup}/terms`;
const adminGradeBookList = `${adminGradebook}/list`;
// const adminGradeBookEdit = `${adminGradebook}/edit`

const gradeBookRoutes = {
  home,
  setup,
  setupItems,
  gradeScale,
  gradeItems,
  viewDetails,
};

const gradeBookAdminRoutes = {
  adminGradebook,
  adminGradebookSetup,
  adminGradebookSetupDetails,
  adminGradebookSetupTerms,
  adminGradeBookList,
  // adminGradeBookEdit
};
const generateGradeBookRoutes = (baseUrl: string) => {
  return {
    home: `${baseUrl}${gradeBookRoutes.home}`,
    gradeStudent: `${baseUrl}${gradeBookRoutes.home}/grade-item/:gradeItemId/students`,
    setup: `${baseUrl}${gradeBookRoutes.setup}`,
    setupItems: `${baseUrl}${gradeBookRoutes.setupItems}`,
    gradeScale: `${baseUrl}${gradeBookRoutes.setup}${gradeBookRoutes.gradeScale}`,
    course: `${baseUrl}${gradeBookRoutes.home}/course`,
    gradeItems: `${baseUrl}${gradeBookRoutes.gradeItems}`,
    viewDetails: `${gradeBookRoutes.viewDetails}`,
  };
};
export { gradeBookRoutes, gradeBookAdminRoutes, generateGradeBookRoutes };
