export const LiveClassesLinkTabs = [
  {
    id: "list",
    label: "Live classes"
  }, 
  {
    id: "add",
    label: "Add live classes"
  }
];

export const SETUP_LIVE_CLASSES_ROUTE = '/live-classes/setup';

export const ADD_LIVE_CLASSES_ROUTE = `${SETUP_LIVE_CLASSES_ROUTE}/add`;

export const LIST_LIVE_CLASSES_ROUTE = `${SETUP_LIVE_CLASSES_ROUTE}/list`;

export const EDIT_LIVE_CLASSES_ROUTE = `${SETUP_LIVE_CLASSES_ROUTE}/:liveClassId/edit`;

const setUpLiveClassesRoutes = {
  root: SETUP_LIVE_CLASSES_ROUTE,
  add: ADD_LIVE_CLASSES_ROUTE,
  list: LIST_LIVE_CLASSES_ROUTE,
  edit: EDIT_LIVE_CLASSES_ROUTE,
};

export const setUpLiveClassesFieldNames = [
  "Course Name",
  "Meet Link",
  "Zoom Link",
  "Host Key",
  "Zoom Password",
];

export default setUpLiveClassesRoutes;
