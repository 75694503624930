import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { PostStateType } from 'src/scenes/myClassroom/Forum/reducers/post/post';
import { PostReducer } from '../scenes/myClassroom/Forum/reducers';
import analytics from '../services/analytics/reducer';
import { AnalyticsStoreStateType } from '../services/analytics/state';
import courseanalytics from '../services/courseanalytics/reducer';
import { CourseAnalyticsStateType } from '../services/courseanalytics/state';
import globalProgress from '../services/global/reducers';
import { GlobalProgressState } from '../services/global/state';
import homeRegistry, { HomeRegistryStateType } from '@fuse/cognito/dist/Reducer/HomeRegistry';
import student from '../services/student/reducer';
import { StudentStoreStateType } from '../services/student/state';
import session, { SessionStoreStateType } from '@fuse/cognito/dist/Reducer/Session';

export interface RootState {
  post: PostStateType;
  session: SessionStoreStateType;
  globalProgress: GlobalProgressState;
  homeRegistry: HomeRegistryStateType;
  analytics: AnalyticsStoreStateType;
  student: StudentStoreStateType;
  courseanalytics: CourseAnalyticsStateType;
}

export default combineReducers({
  post: PostReducer,
  session,
  globalProgress,
  homeRegistry,
  analytics,
  student,
  courseanalytics,
  router: routerReducer,
});
