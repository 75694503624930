import React, { useState } from 'react';
import { Dropdown, Form, FormControl, InputGroup, Nav, Navbar } from 'react-bootstrap';
import { useRouter } from '@fuse/utils/dist/useRouter';
import { isFuseUpskill } from '@fuse/terminology';
import { removeNotificationToken } from '@fuse/services/dist/Notification';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import useAuth from '@fuse/cognito/dist/utils/useAuth';
interface IAppNavbarProps {
  isHomeNavbar?: boolean;
  navBarHeader?: string;
  searchText?: string;
  setSearchText?: (value: string) => void;
  hideSearchInput?: boolean;
}

const AppNavbar: React.FC<IAppNavbarProps> = ({
  searchText,
  setSearchText = () => {},
  isHomeNavbar,
  navBarHeader,
  hideSearchInput,
}) => {
  const userInfo = useSelector((state: RootState) => state.session.user);

  const { logout } = useAuth();

  // for fixed multiple click on logout
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { location, push } = useRouter();
  const getNavTitle = () => {
    try {
      const urlSplit = location.pathname.split('/');
      const urlTitle = urlSplit[1].replace('-', ' '); // 0 is always empty so 1
      if (urlTitle && urlTitle.toLocaleLowerCase() === 'assignment') {
        return 'Assignments';
      }
      if (urlTitle && urlTitle.toLocaleLowerCase() === 'grade') {
        return 'Grades';
      }
      if (urlTitle && urlTitle.toLocaleLowerCase() === 'staffs') {
        return 'Staff';
      }
      return urlTitle;
    } catch (err) {
      return '';
    }
  };

  const _handleProfileClick = () => {
    if (!location.pathname.includes('profile')) {
      push('/profile');
    }
  };

  const _handleOnLogout = async () => {
    setIsLoading(true);
    await removeNotificationToken();
    logout();
  };
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand className={`mr-2  mr-md-4 ${isHomeNavbar ? ' lg ' : ''}`} onClick={() => push('/home')}>
        {isFuseUpskill ? (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="151.504" height="26.39" viewBox="0 0 151.504 26.39">
              <g id="Fuseupskill_logo" data-name="Fuseupskill logo" transform="translate(-311.248 -4778)">
                <g id="Layer_x0020_1" transform="translate(311.248 4778)">
                  <path
                    id="Path_28087"
                    data-name="Path 28087"
                    d="M10.967,19.445H9.1V15.653h1.867V15.3c0-4.757,4.549-5.4,7.027-5.4a11.685,11.685,0,0,1,1.562.1V14.11a5.565,5.565,0,0,0-.849-.064c-1.018,0-2.58.161-2.58,1.414v.193h2.818v3.792H16.127V30.5h-5.16ZM77.875,9.932h1.7v26.29h-1.7Zm-12.9,11.12a1.812,1.812,0,0,0-1.765-1.8,2.408,2.408,0,0,0-2.512,1.8Zm-1.765-5.4c4.413,0,6.925,2.925,6.925,6.846,0,.482-.1,1.414-.1,1.414H60.6a3.3,3.3,0,0,0,3.5,2.571,7.194,7.194,0,0,0,3.972-1.478l2,3.182a9.964,9.964,0,0,1-6.382,2.25c-5.33,0-8.215-3.664-8.215-7.424-.068-4.114,2.919-7.36,7.74-7.36Zm-20.1,9.192A7.693,7.693,0,0,0,47.6,26.869c.747,0,1.154-.193,1.154-.675,0-1.189-7.672-1.575-7.672-6.171,0-3.053,3.055-4.371,6.314-4.371,2.512,0,5.941.675,5.941,3.214v1.414H48.885v-.418c0-.386-.645-.675-1.392-.675-.713,0-1.358.225-1.358.675,0,1.446,7.774,1.253,7.774,6.042,0,2.86-2.716,4.6-6.28,4.6a9.62,9.62,0,0,1-6.789-2.6ZM22.305,20.056a.541.541,0,0,0-.17-.45.609.609,0,0,0-.475-.161H20.54V15.653h4.753c1.528,0,2.173.611,2.173,2.057v6.428c0,3.085,4.888,2.828,4.888,0V15.653h5.16v9.77a.541.541,0,0,0,.17.45.609.609,0,0,0,.475.161h1.12v4.435H34.73a2.122,2.122,0,0,1-1.525-.558,1.9,1.9,0,0,1-.614-1.435V27.93h-.068a5.366,5.366,0,0,1-4.753,2.507c-3.021,0-5.431-1.35-5.431-5.432Z"
                    transform="translate(-9.1 -9.899)"
                    fill="#6d49b1"
                  />
                </g>
                <g id="Group_15553" data-name="Group 15553" transform="translate(389.258 4778)">
                  <path
                    id="Path_28088"
                    data-name="Path 28088"
                    d="M240.224,28.6v8.631a3.769,3.769,0,0,0,.951,2.907,4.207,4.207,0,0,0,2.919.918,5.373,5.373,0,0,0,3.87-1.194,5.325,5.325,0,0,0,1.256-3.917V28.6h1.324V41.913h-1.154l-.238-1.836h-.068c-.951,1.377-2.682,2.081-5.126,2.081-3.361,0-5.058-1.622-5.058-4.866V28.6Z"
                    transform="translate(-238.9 -22.252)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28089"
                    data-name="Path 28089"
                    d="M293.948,43.144a5.44,5.44,0,0,1-4.99-2.546h-.1l.034,1.154c.034.679.068,1.392.068,2.207v5.635H287.6V28.072h1.154l.272,2.1h.068a6.19,6.19,0,0,1,9.4-.407,8.984,8.984,0,0,1,1.562,5.7,8.458,8.458,0,0,1-1.663,5.635A5.315,5.315,0,0,1,293.948,43.144Zm-.034-1.222a4.166,4.166,0,0,0,3.53-1.7,7.88,7.88,0,0,0,1.256-4.719c0-4.345-1.562-6.518-4.719-6.518a4.724,4.724,0,0,0-3.8,1.426c-.815.951-1.222,2.478-1.222,4.617v.441c0,2.308.373,3.972,1.154,4.956a4.5,4.5,0,0,0,3.8,1.494Z"
                    transform="translate(-271.068 -23.204)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28090"
                    data-name="Path 28090"
                    d="M343.089,38.053a3.116,3.116,0,0,1-1.494,2.758,7.924,7.924,0,0,1-4.277.95,11.283,11.283,0,0,1-4.719-.828V39.647a11.3,11.3,0,0,0,4.719,1.011,6.854,6.854,0,0,0,3.327-.644,1.955,1.955,0,0,0,1.12-1.747,2.038,2.038,0,0,0-.917-1.686,10.233,10.233,0,0,0-2.953-1.349,16.93,16.93,0,0,1-3.123-1.257,4.006,4.006,0,0,1-1.358-1.165,2.712,2.712,0,0,1-.441-1.594,2.671,2.671,0,0,1,1.392-2.36,7.351,7.351,0,0,1,3.9-.858,13.2,13.2,0,0,1,4.549.8l-.509,1.1a11.315,11.315,0,0,0-4.04-.8,6.059,6.059,0,0,0-2.852.552,1.646,1.646,0,0,0-1.052,1.5,1.936,1.936,0,0,0,.815,1.655,11.754,11.754,0,0,0,3.225,1.41,17.97,17.97,0,0,1,2.919,1.165,3.758,3.758,0,0,1,1.358,1.2A2.434,2.434,0,0,1,343.089,38.053Z"
                    transform="translate(-300.792 -21.856)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28091"
                    data-name="Path 28091"
                    d="M376.024,24.565l7.536-8.283h1.629l-5.8,6.314,6.314,8.453h-1.629l-5.6-7.468-2.41,2.207v5.262H374.7V9.9h1.358V20.491l-.1,4.074Z"
                    transform="translate(-328.601 -9.9)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28092"
                    data-name="Path 28092"
                    d="M413.8,14.087c0-.883.272-1.29.849-1.29a.7.7,0,0,1,.645.339,1.58,1.58,0,0,1,.238.95,1.58,1.58,0,0,1-.238.951.735.735,0,0,1-.645.339C414.072,15.377,413.8,14.936,413.8,14.087Zm1.528,18.874H413.97v-14.8h1.358Z"
                    transform="translate(-354.428 -11.813)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28093"
                    data-name="Path 28093"
                    d="M434.258,31.049H432.9V9.9h1.358Z"
                    transform="translate(-367.044 -9.9)"
                    fill="#6d49b1"
                  />
                  <path
                    id="Path_28094"
                    data-name="Path 28094"
                    d="M452.758,31.049H451.4V9.9h1.358Z"
                    transform="translate(-379.264 -9.9)"
                    fill="#6d49b1"
                  />
                </g>
              </g>
            </svg>
          </div>
        ) : (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="33.942" height="36.406" viewBox="0 0 33.942 36.406">
              <g id="Group_11805" data-name="Group 11805" transform="translate(1807 -1935.346)">
                <g id="Group_11804" data-name="Group 11804" transform="translate(-1807 1935.346)">
                  <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="33.811"
                    height="2.212"
                    rx="1.106"
                    transform="translate(0 34.194)"
                    fill="#174e4d"
                  />
                  <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M1736.131,2630.4l-14.777,9.426v-25l13.945-8.895a2.023,2.023,0,0,1,3.024,1.929V2626.2A4.957,4.957,0,0,1,1736.131,2630.4Z"
                    transform="translate(-1704.381 -2605.629)"
                    fill="#174e4d"
                  />
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M1694.521,2626.2V2607.86a2.024,2.024,0,0,1,3.025-1.929l13.945,8.895v25l-14.777-9.426A4.959,4.959,0,0,1,1694.521,2626.2Z"
                    transform="translate(-1694.521 -2605.629)"
                    fill="#439c7c"
                  />
                </g>
              </g>
            </svg>
          </div>
        )}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Navbar.Text>
          <div className="main--title">{navBarHeader || getNavTitle()}</div>
        </Navbar.Text>

        {isHomeNavbar && !hideSearchInput && (
          <Nav className="mx-auto">
            <Form inline>
              <InputGroup className="search float-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path
                        id="Path_24010"
                        data-name="Path 24010"
                        d="M23.734,22.448l-5.159-5.159a8.183,8.183,0,1,0-1.286,1.285l5.159,5.159a.909.909,0,0,0,1.286-1.285Zm-11.552-3.9a6.364,6.364,0,1,1,6.364-6.364A6.364,6.364,0,0,1,12.182,18.545Z"
                        transform="translate(-4 -4)"
                        fill="#c7c7c7"
                      />
                    </svg>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search Your App"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  value={searchText}
                  onChange={(event: any) => setSearchText(event.target.value)}
                />
              </InputGroup>
            </Form>
          </Nav>
        )}

        <Nav className="ml-auto">
          <Dropdown className="user">
            <Dropdown.Toggle as={'div'} id="dropdown-user" className="pointer">
              <img
                className="user-img img-fluid"
                src={userInfo?.image ? userInfo.image : '/image/generic-user-img.png'}
                alt="User"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <ul>
                <li className="dropdown--item" onClick={_handleProfileClick}>
                  <div className="user-info">
                    <span className="user--img-hold">
                      <img src={userInfo?.image ? userInfo.image : '/image/generic-user-img.png'} alt="User" />
                    </span>
                    <span className="user--email">{userInfo?.email}</span>
                  </div>
                </li>
                <li
                  className="dropdown--item"
                  onClick={() => {
                    if (!isLoading) {
                      _handleOnLogout();
                    }
                  }}
                >
                  <button className="btn btn-link">Logout</button>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
