export var isPositiveNumber = function (value) {
    if (!value) {
        return false;
    }
    var number = typeof value === 'number' ? "" + value : value;
    number = number.trim();
    if (!number) {
        return false;
    }
    number = number.replace(/^0+/, '') || '0';
    var n = Math.floor(Number(number));
    return n !== Infinity && String(n) === number && n > 0;
};
export var getDecimalNumber = function (number, numberOfDigit) {
    if (numberOfDigit === void 0) { numberOfDigit = 2; }
    return !Number.isNaN(parseInt(number)) ? parseFloat(number).toFixed(numberOfDigit) : 0.0;
};
export var calPercent = function (percent, number) {
    return (percent * number) / 100;
};
