export const COURSE_CATALOG = '/course-catalog';
export const ADD_COURSE_CATALOG = '/course-catalog/add';
export const LIST_COURSE_CATALOG = '/course-catalog/list';
export const EDIT_COURSE_CATALOG = '/course-catalog/:courseCatalogId/edit';
const courseRoute = {
  root: COURSE_CATALOG,
  add: ADD_COURSE_CATALOG,
  list: LIST_COURSE_CATALOG,
};

export default courseRoute;
