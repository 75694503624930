import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getCodedAssignmentFileDownload } from '../services';
import { useDownloadFile } from '@fuse/ui-service';
import PreviewNotebookFile from './PreviewNotebookFile';
import { FTooltip } from '@fuse/ui-components/dist/FTooltip';
export var ResourceFileList = function (props) {
    var resourceFiles = props.resourceFiles;
    var _a = useDownloadFile(), downloadFile = _a.downloadFile, downloadModal = _a.downloadModal;
    var handleDownloadResourceFile = function (fileId, fileName) {
        downloadFile(getCodedAssignmentFileDownload(fileId), fileName);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "title mt-2" }, "Resources Files"),
        React.createElement(Row, null, (resourceFiles || []).map(function (resourceFile) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Col, { md: 6, className: "mb-4" },
                    React.createElement("div", { className: "block" },
                        React.createElement("div", { className: "file-list" },
                            React.createElement("div", { className: "file-details" },
                                React.createElement("span", { className: "icon-file" }),
                                React.createElement(FTooltip, { tooltipMessage: resourceFile.originalFilename, position: "bottom-start" },
                                    React.createElement("div", { className: "file-name" }, resourceFile.originalFilename))),
                            React.createElement("ul", { className: "action" },
                                React.createElement("li", { className: "action--item", onClick: function () { return handleDownloadResourceFile(resourceFile.id, resourceFile.originalFilename); } },
                                    React.createElement("span", { className: "icon-download icon-download-increase" })),
                                React.createElement(PreviewNotebookFile, { fileId: resourceFile.id, fileName: resourceFile.originalFilename })))))));
        })),
        downloadModal));
};
