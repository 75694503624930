import { terminology, pluralize } from '@fuse/terminology';

export const ACADEMIC_SETUP_ROUTE = `/${terminology.academic.toLowerCase()}`;
export const ACADEMIC_ADD_ROUTE = `${ACADEMIC_SETUP_ROUTE}/add`;
export const ACADEMIC_LIST_ROUTE = `${ACADEMIC_SETUP_ROUTE}/list`;
export const ACADEMIC_ASSIGN_STUDENTS = `${ACADEMIC_SETUP_ROUTE}/assign-${pluralize(
  terminology.student.toLowerCase(),
)}`;
// export const ACADEMIC_PROGRAM_SETUP_ROUTE = "/academic/:academicId/setup";
export const ACADEMIC_VIEW_DETAIL = `${ACADEMIC_SETUP_ROUTE}/:academicId/:programId`;
export const ACADEMIC_VIEW_DETAIL_OVERVIEW = `${ACADEMIC_VIEW_DETAIL}/overview`;
export const ACADEMIC_VIEW_DETAIL_COURSES = `${ACADEMIC_VIEW_DETAIL}/course`;
export const ACADEMIC_VIEW_DETAIL_STUDENTS = `${ACADEMIC_VIEW_DETAIL}/${pluralize(terminology.student.toLowerCase())}`;
export const ACADEMIC_VIEW_DETAIL_INSTRUCTORS = `${ACADEMIC_VIEW_DETAIL}/${pluralize(
  terminology.instructor.toLowerCase(),
)}`;
export const ACADEMIC_VIEW_DETAIL_CLASS = `${ACADEMIC_VIEW_DETAIL}/class`;
export const ACADEMIC_EDIT_ROUTE = `${ACADEMIC_SETUP_ROUTE}/edit/:academicId`;
export const ACADEMIC_PROGRAM_EDIT_ROUTE = `${ACADEMIC_SETUP_ROUTE}/edit/:academicId/:programId`;
export const ACADEMIC_PROGRAM_EDIT_FROM_OVERVIEW_ROUTE = `${ACADEMIC_SETUP_ROUTE}/overview/edit/:academicId/:programId`;
export const ACADEMIC_ASSIGN_COURSES = `${ACADEMIC_SETUP_ROUTE}/assign-courses`;
export const ACADEMIC_EDIT_COURSES = `${ACADEMIC_SETUP_ROUTE}/edit-courses`;
export const ACADEMIC_COURSE_DETAIL = `${ACADEMIC_SETUP_ROUTE}/course-detail`;
export const ACADEMIC_MOVE_STUDENTS = `${ACADEMIC_SETUP_ROUTE}/move-${pluralize(terminology.student.toLowerCase())}`;
export const ACADEMIC_ASSIGN_INSTRUCTORS = `${ACADEMIC_SETUP_ROUTE}/assign-${pluralize(
  terminology.instructor.toLowerCase(),
)}`;
export const ACADEMIC_ASSIGN_CLASS = `${ACADEMIC_SETUP_ROUTE}/assign-class`;
export const ACADEMIC_ASSIGN_CLASS_COURSES = `${ACADEMIC_SETUP_ROUTE}/assign-class-courses`;
export const ACADEMIC_EDIT_CLASS = `${ACADEMIC_SETUP_ROUTE}/edit-class`;
export const ACADEMIC_EDIT_CLASS_COURSES = `${ACADEMIC_SETUP_ROUTE}/edit-class-courses`;

const academicYearRoutes = {
  root: ACADEMIC_SETUP_ROUTE,
  add: ACADEMIC_ADD_ROUTE,
  // programsetup: ACADEMIC_PROGRAM_SETUP_ROUTE,
  viewDetail: ACADEMIC_VIEW_DETAIL,
  list: ACADEMIC_LIST_ROUTE,
  edit: ACADEMIC_EDIT_ROUTE,
  programEdit: ACADEMIC_PROGRAM_EDIT_ROUTE,
};

export default academicYearRoutes;
