import { StudentStoreState } from './state';
import * as types from './types';

export default function reducer(state = StudentStoreState, action: any) {
  switch (action.type) {
    case types.STUDENT_INFO:
    case types.STUDENT_INFO_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    /*  case types.STUDENT_INFO_ERROR:
      return {
        ...state,
        ...action.payload,
      }; */

    case types.MY_ACCOMPLISHMENT:
      return {
        ...state,
        myAccomplishment: action.payload,
      };

    default:
      return state;
  }
}
