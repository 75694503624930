import { useState, useEffect } from 'react';
var useWindowSize = function () {
    var isMobileDevice = function () {
        return window.innerWidth <= 700;
    };
    var _a = useState(isMobileDevice()), isMobile = _a[0], setIsMobile = _a[1];
    useEffect(function () {
        window.addEventListener('resize', function () {
            setIsMobile(isMobileDevice());
        });
        return function () {
            window.removeEventListener('resize', function () {
                console.log('Remove resize event listener');
            }, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { isMobile: isMobile };
};
export default useWindowSize;
