var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState } from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import { getCodedAssignmentFileDownload } from '../services';
import { useDownloadFile } from '@fuse/ui-service';
import moment from 'moment';
import { GradeNumbers } from './GradeNumber';
import EmptyAssignments from './EmptyAssignments';
import PreviewIPYNBFile from '@fuse/ui-service/dist/PreviewIPYNBFile';
import { getAssignmentPreviewFileRequest } from '../services';
import { isFileType, FileType } from '@fuse/utils/dist/fileUtils';
import { Form } from 'react-final-form';
import { FinalInput } from '@fuse/ui-components';
import { updateManualGrade } from '@fuse/services/dist/Assignments';
import { useRouter } from '@fuse/utils/dist/useRouter';
import { getErrorMessage } from '@fuse/services/dist/Axios/index';
import { showError, showSuccess } from '@fuse/utils/dist/alertNotification';
import { terminology } from '@fuse/terminology';
import { validateGrade } from '../Utils';
export var CodedAssignmentSubmissionList = function (props) {
    var notebookSubmissionFiles = props.notebookSubmissionFiles, activeGrade = props.activeGrade, autoGradedAssignment = props.autoGradedAssignment;
    var _a = useDownloadFile(), downloadFile = _a.downloadFile, downloadModal = _a.downloadModal;
    var query = useRouter().query;
    var assignmentId = query.assignmentId;
    var _b = useState({
        grade: null,
        isLoading: false,
        error: '',
        saveMessage: '',
    }), _gradeData = _b[0], setGradeData = _b[1];
    var _c = useState(), activeTask = _c[0], setActiveTask = _c[1];
    var _d = useState(), activeSubmittedFile = _d[0], setActiveSubmittedFile = _d[1];
    var handleDownloadSubmissionFile = function (fileId, fileName) {
        downloadFile(getCodedAssignmentFileDownload(fileId), activeGrade ? activeGrade.studentName + '-' + fileName : fileName);
    };
    var onSaveGrade = function (values, notebookSubmissionFilesId, taskId) { return __awaiter(void 0, void 0, void 0, function () {
        var mark, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    mark = values.mark;
                    if (!(mark && (activeGrade === null || activeGrade === void 0 ? void 0 : activeGrade.studentId))) return [3 /*break*/, 2];
                    setGradeData(__assign(__assign({}, _gradeData), { isLoading: true, error: '', saveMessage: 'Saving...' }));
                    return [4 /*yield*/, updateManualGrade({
                            assignmentId: assignmentId,
                            studentId: activeGrade.studentId,
                            notebookSubmissionFilesId: notebookSubmissionFilesId,
                            taskId: taskId,
                            obtainedMarks: mark,
                        })];
                case 1:
                    _a.sent();
                    setGradeData(__assign(__assign({}, _gradeData), { isLoading: false, error: '', saveMessage: 'Saved!' }));
                    showSuccess(terminology.grade + " updated successfully.");
                    if (props.onUpdateMark) {
                        props.onUpdateMark();
                    }
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setGradeData(__assign(__assign({}, _gradeData), { isLoading: false, error: '', saveMessage: '' }));
                    showError(getErrorMessage(e_1));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var gradeGroupByNotebookFileId = getGradeGroupByNotebookFileId(notebookSubmissionFiles);
    var accordionGroupByNotebookId = gradeGroupByNotebookFileId.map(function (notebookGroupByNotebookFileId, accordionIndex) {
        // get notebookFileId
        var notebookFileId = Object.keys(notebookGroupByNotebookFileId)[0];
        var submittedNotebookList = notebookGroupByNotebookFileId[notebookFileId];
        var highestSubmittedGrade = getHighestSubmittedGrade(submittedNotebookList);
        var dynamicHeaderNames = getDynamicHeaderNames(submittedNotebookList);
        return (React.createElement(Accordion, { className: "submission--accordion mt-2 mb-3", defaultActiveKey: "0", key: accordionIndex },
            React.createElement(Accordion.Collapse, { eventKey: "0" },
                React.createElement("div", null,
                    React.createElement("div", { className: "react-bootstrap-table" },
                        React.createElement("table", { className: "table coded-grades border-left w-100" },
                            React.createElement(Header, { dynamicHeaderNames: dynamicHeaderNames }),
                            React.createElement("tbody", null, submittedNotebookList.map(function (submittedNotebook, submittedNotebookIndex) {
                                return (React.createElement("tr", { className: submittedNotebook.id === highestSubmittedGrade.submissionId
                                        ? 'table-highlight font-weight-bold'
                                        : '' },
                                    React.createElement("td", null, moment(+submittedNotebook.addedOn).format('L')),
                                    React.createElement("td", null, moment(+submittedNotebook.addedOn).format('H:mm:ss')),
                                    React.createElement("td", null,
                                        React.createElement("div", { className: "d-flex" },
                                            submittedNotebook.originalFilename,
                                            React.createElement("span", { className: 'icon-download icon-download-increase pointer ml-3 text-success', onClick: function () {
                                                    return handleDownloadSubmissionFile(submittedNotebook.id, submittedNotebook.originalFilename);
                                                } }),
                                            isFileType(submittedNotebook.originalFilename, FileType.ipynb) && (React.createElement(PreviewIPYNBFile, { request: getAssignmentPreviewFileRequest(submittedNotebook.id), fileName: submittedNotebook.originalFilename, modalProps: { modalSize: 'xl' } })))),
                                    submittedNotebook.task && dynamicHeaderNames && dynamicHeaderNames.length > 0 && (React.createElement(TotalSubmittedNotebookGrade, { highestSubmittedGrade: highestSubmittedGrade, submittedNotebook: submittedNotebook })),
                                    submittedNotebook.task ? (React.createElement(React.Fragment, null, submittedNotebook.task.map(function (task) {
                                        return (React.createElement("td", null, submittedNotebookIndex === submittedNotebookList.length - 1 &&
                                            props.onUpdateMark ? (React.createElement(Form, { onSubmit: function (values) {
                                                return onSaveGrade(values, submittedNotebook.id, task.taskId);
                                            }, initialValues: {
                                                mark: "" + task.obtainedMarks,
                                                fullMarks: task.total,
                                            }, render: function (formProps) {
                                                return (React.createElement("form", { onSubmit: formProps.handleSubmit },
                                                    React.createElement("div", { className: "d-flex flex-lg-column" },
                                                        React.createElement("div", { className: "d-flex align-items-center" },
                                                            React.createElement(FinalInput, { className: "task-input table-input", legend: "", name: "mark", type: "number", max: task.total, min: 0, formProps: formProps, onFocus: function () {
                                                                    setActiveTask(task.taskId);
                                                                    setActiveSubmittedFile(submittedNotebook.id);
                                                                }, onBlur: function () {
                                                                    setActiveTask('');
                                                                    setGradeData(__assign(__assign({}, _gradeData), { isLoading: false, error: '', saveMessage: '' }));
                                                                }, 
                                                                // autoFocus
                                                                inline: true, validate: validateGrade, hideAsterisk: true, requirementMsg: "" // hide error message for below custom message for css fixes
                                                                , decimal: true }),
                                                            React.createElement("span", null,
                                                                "/",
                                                                task.total),
                                                            console.log(task.obtainedMarks, formProps.values.mark)),
                                                        React.createElement(React.Fragment, null, activeTask === task.taskId &&
                                                            activeSubmittedFile === submittedNotebook.id && (React.createElement("div", { className: "d-flex" },
                                                            !_gradeData.isLoading &&
                                                                !formProps.errors['mark'] &&
                                                                "" + task.obtainedMarks !== "" + formProps.values.mark && (React.createElement("span", { className: "helper ml-2" }, "Enter to save!")),
                                                            formProps.errors['mark'] && !formProps.pristine ? (React.createElement("span", { className: "error ml-2" }, "" + formProps.errors['mark'])) : (React.createElement("span", { className: "helper ml-2" }, _gradeData.saveMessage))))))));
                                            } })) : (React.createElement(React.Fragment, null,
                                            task.obtainedMarks,
                                            "/",
                                            task.total))));
                                    }))) : (React.createElement(React.Fragment, null, dynamicHeaderNames.map(function (header) { return (React.createElement("td", null, "-")); })))));
                            })))))),
            React.createElement(Accordion.Toggle, { className: "mb-3 d-flex w-100  justify-content-between align-items-center border-bottom mb-4", as: 'div', eventKey: "0" },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "font-weight-semibold" }, submittedNotebookList[0].notebookTitle),
                    React.createElement("div", null,
                        autoGradedAssignment && (React.createElement(GradeNumbers, { gradeLabel: "Grade", fullMarks: highestSubmittedGrade.totalGrade, totalGrade: highestSubmittedGrade.totalObtainMarks })),
                        React.createElement("span", { className: "icon-angle" }))))));
    });
    return (React.createElement(React.Fragment, null,
        notebookSubmissionFiles.length === 0 ? (React.createElement(EmptyAssignments, { title: 'No submissions at the moment' })) : (React.createElement(Row, null,
            React.createElement(Col, { md: 12 }, accordionGroupByNotebookId))),
        downloadModal));
};
var getGradeGroupByNotebookFileId = function (notebookSubmissionFiles) {
    return notebookSubmissionFiles.reduce(function (acc, item) {
        var _a;
        if (item.notebookFileId) {
            // check if date is already exists in list
            var index = acc.findIndex(function (oldData) { return Object.keys(oldData)[0] === item.notebookFileId; });
            if (index > -1) {
                // add INotebookGroupByNotebookFileId to already exits array
                var indexObject = acc[index];
                indexObject[item.notebookFileId].push(item);
            }
            else {
                // create INotebookGroupByNotebookFileId object to array
                acc.push((_a = {}, _a[item.notebookFileId] = [item], _a));
            }
        }
        return acc;
    }, []);
};
var getDynamicHeaderNames = function (submittedNotebookList) {
    var submittedNotebookWithTask = submittedNotebookList.find(function (submittedNotebook) { return submittedNotebook.task; });
    if (submittedNotebookWithTask) {
        return __spreadArrays(['total'], submittedNotebookWithTask.task.map(function (task) { return "" + task.taskId + (task.manuallyGraded ? ' (M)' : ''); }));
    }
    return [];
};
var Header = function (props) {
    // create header for TaskId
    var dynamicHeader = props.dynamicHeaderNames.map(function (taskId) { return React.createElement("th", null, taskId); });
    return (React.createElement("thead", null,
        React.createElement("tr", null,
            React.createElement("th", null, "date"),
            React.createElement("th", null, "time"),
            React.createElement("th", null, "submitted file"),
            dynamicHeader)));
};
var TotalSubmittedNotebookGrade = function (props) {
    var highestSubmittedGrade = props.highestSubmittedGrade, submittedNotebook = props.submittedNotebook;
    var totalSubmittedGrade = getTotalSubmittedMarks(submittedNotebook.task);
    return (React.createElement("td", null,
        totalSubmittedGrade.totalObtainMarks,
        "/",
        totalSubmittedGrade.totalGrade,
        submittedNotebook.id === highestSubmittedGrade.submissionId && ' (H)'));
};
var getTotalSubmittedMarks = function (task) {
    return task.reduce(function (acc, value) {
        return {
            totalObtainMarks: acc.totalObtainMarks + value.obtainedMarks,
            totalGrade: acc.totalGrade + value.total,
        };
    }, { totalObtainMarks: 0, totalGrade: 0 });
};
var getHighestSubmittedGrade = function (submittedNotebookList) {
    return submittedNotebookList.reduce(function (acc, item) {
        if (item.task) {
            var totalSubmittedMarks = getTotalSubmittedMarks(item.task);
            if (totalSubmittedMarks.totalObtainMarks >= acc.totalObtainMarks) {
                acc.submissionId = item.id;
                acc.totalObtainMarks = totalSubmittedMarks.totalObtainMarks;
                acc.totalGrade = totalSubmittedMarks.totalGrade;
            }
        }
        return acc;
    }, { submissionId: '', totalObtainMarks: 0, totalGrade: 0 });
};
