var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import moment from 'moment';
import React, { createContext, useEffect, useRef, useState } from 'react';
import Firebase from '../../config';
import { getAllMessage, getAnnouncementById, getMessageById, getNotificationCount, markAllAsRead, markMessageAsRead, } from '@fuse/services/dist/Notification';
export var NotificationContext = createContext({});
export var NOTIFICATION = 'notification';
var NotificationProvider = function (props) {
    var _a = useState({
        notifications: [],
        loading: true,
        page: 1,
        size: 10,
        totalPages: 0,
        reFetch: true,
    }), _notificationData = _a[0], _setNotificationData = _a[1];
    var _b = useState(false), showSideNotification = _b[0], setShowSideNotification = _b[1];
    // to show the date wise notification in side bar
    var _c = useState([]), _dateNotifications = _c[0], _setDateNotifications = _c[1];
    var _d = useState({}), activeNotification = _d[0], setActiveNotification = _d[1];
    var _e = useState(false), _showNotificationDetail = _e[0], _setShowNotificationDetail = _e[1];
    var _f = useState(0), _notificationBadgeCount = _f[0], _setNotificationBadgeCount = _f[1];
    var _g = useState([]), _popupNotifications = _g[0], _setPopupNotifications = _g[1];
    // use useRef for setTimeout to get current value
    var _popupNotificationsRef = useRef(_popupNotifications);
    _popupNotificationsRef.current = _popupNotifications;
    /**
     *  refresh notification after change tab
     */
    useEffect(function () {
        var handleVisibilityChange = function () {
            if (!document.hidden) {
                _setNotificationData(__assign(__assign({}, _notificationData), { reFetch: true, loading: true, page: 1 }));
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     *  For firebase onMessagae when page is active in browser
     */
    useEffect(function () {
        var initializeFirebase = function () { return __awaiter(void 0, void 0, void 0, function () {
            var firebase, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        firebase = new Firebase();
                        return [4 /*yield*/, firebase.init()];
                    case 1:
                        _a.sent();
                        if (firebase.messaging) {
                            firebase.messaging.onMessage(function (payload) {
                                if (payload && payload.data && payload.data.source !== NOTIFICATION) {
                                    handleOnAnnouncementMessage(payload);
                                }
                                else {
                                    handleOnNotificationMessage(payload);
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        initializeFirebase();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleOnAnnouncementMessage = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var message, notification_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getAnnouncementById(payload.data.messageId)];
                case 1:
                    message = _a.sent();
                    notification_1 = message;
                    notification_1.show = true;
                    _setPopupNotifications(__spreadArrays(_popupNotifications, [notification_1]));
                    setTimeout(function () {
                        deletePopupNotification(notification_1.id);
                    }, 1000000);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleOnNotificationMessage = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var message, _a, notification_2, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(payload && payload.data && payload.data.source === NOTIFICATION)) return [3 /*break*/, 7];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    if (!payload.data.type) return [3 /*break*/, 3];
                    return [4 /*yield*/, getMessageById(payload.data.messageId)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, getAnnouncementById(payload.data.messageId)];
                case 4:
                    _a = _b.sent();
                    _b.label = 5;
                case 5:
                    message = _a;
                    notification_2 = message;
                    notification_2.show = true;
                    notification_2.read = false;
                    // add notification in sidebar
                    // _setNotificationData({
                    //   ..._notificationData,
                    //   ...{
                    //     notifications: [..._notificationData.notifications, ...[notification]],
                    //     loading: false,
                    //   },
                    // });
                    // refetch notificaion => notificationData is been reset after push notification onmessaage need to fix later
                    _setNotificationData(__assign(__assign({}, _notificationData), { reFetch: true, loading: true }));
                    // add notification for popup notification
                    _setPopupNotifications(__spreadArrays(_popupNotifications, [notification_2]));
                    setTimeout(function () {
                        deletePopupNotification(notification_2.id);
                    }, 3000);
                    return [3 /*break*/, 7];
                case 6:
                    error_3 = _b.sent();
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Remove popup notification
     * @param messageId
     */
    var deletePopupNotification = function (messageId) {
        var popupNotificationList = __spreadArrays(_popupNotificationsRef.current);
        var index = popupNotificationList.findIndex(function (notification) { return notification.id === messageId; });
        if (index > -1) {
            popupNotificationList[index].show = false;
            _setPopupNotifications(popupNotificationList);
        }
    };
    /**
     *  Fetch notification ( messages ) for sidebar
     */
    useEffect(function () {
        var fetchMessages = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAllMessage({ page: _notificationData.page, size: _notificationData.size })];
                    case 1:
                        data = _a.sent();
                        _setNotificationData(__assign(__assign({}, _notificationData), {
                            notifications: data.content,
                            loading: false,
                            totalPages: data.totalPages,
                            reFetch: false,
                        }));
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (_notificationData.reFetch) {
            fetchMessages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_notificationData.reFetch]);
    /**
     *  Fetch notification on page change
     */
    useEffect(function () {
        var fetchMessagesOnPageChange = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAllMessage({ page: _notificationData.page, size: _notificationData.size })];
                    case 1:
                        data = _a.sent();
                        _setNotificationData(__assign(__assign({}, _notificationData), {
                            notifications: __spreadArrays(_notificationData.notifications, data.content),
                            loading: false,
                            totalPages: data.totalPages,
                        }));
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (_notificationData.page > 1) {
            fetchMessagesOnPageChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_notificationData.page]);
    /**
     *  Fetch notification ( messages ) for sidebar
     */
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!_notificationData.reFetch) return [3 /*break*/, 2];
                        return [4 /*yield*/, getNotificationCount()];
                    case 1:
                        _a = _b.sent(), data = _a[1];
                        if (data && data.count) {
                            _setNotificationBadgeCount(data.count);
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })();
    }, [_notificationData.reFetch]);
    /**
     * Set DateMessages IDateMessage[] for date wise notification
     */
    useEffect(function () {
        var formatDateMessagesData = function () {
            var notifications = _notificationData.notifications;
            // sort notification list by date ascending order
            notifications.sort(function (x, y) { return y.modifiedDate - x.modifiedDate; });
            var messageList = notifications;
            var filterDateMessageList = messageList.reduce(function (acc, item) {
                var _a;
                var formattedDate = moment(item.modifiedDate).format('Do MMM, YYYY');
                // for label 'Today' and 'Yesterday'
                if (moment(item.createdDate).isSame(moment(new Date()), 'day')) {
                    formattedDate = 'Today';
                }
                else if (moment(item.createdDate).isSame(moment().subtract(1, 'days').startOf('day'), 'day')) {
                    formattedDate = 'Yesterday';
                }
                // check if date is already exists in list
                var index = acc.findIndex(function (oldData) { return Object.keys(oldData)[0] === formattedDate; });
                if (index > -1) {
                    var indexObject = acc[index];
                    indexObject[formattedDate].push(item);
                }
                else {
                    acc.push((_a = {}, _a[formattedDate] = [item], _a));
                }
                return acc;
            }, []);
            _setDateNotifications(filterDateMessageList);
        };
        formatDateMessagesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_notificationData.notifications]);
    var toggleSideNotification = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setShowSideNotification(!showSideNotification);
            _setNotificationData(__assign(__assign({}, _notificationData), { reFetch: !_notificationData.reFetch, page: 1 }));
            return [2 /*return*/];
        });
    }); };
    var handleOnClickNotification = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setShowSideNotification(false);
                    setActiveNotification(message);
                    _setShowNotificationDetail(true);
                    if (!!message.read) return [3 /*break*/, 2];
                    return [4 /*yield*/, _markMessageAsRead(message.id)];
                case 1:
                    _a.sent();
                    if (_notificationBadgeCount > 0) {
                        _setNotificationBadgeCount(_notificationBadgeCount - 1);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var _OnClickRedirectNotification = function (messageId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, _markMessageAsRead(messageId)];
                case 1:
                    _a.sent();
                    if (_notificationBadgeCount > 0) {
                        _setNotificationBadgeCount(_notificationBadgeCount - 1);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _markMessageAsRead = function (messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, markMessageAsRead(messageId)];
                case 1:
                    _a.sent();
                    _updateMessageAsRead(messageId);
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateNotificationsMarkRead = function () {
        var notifications = _notificationData.notifications.map(function (notification) {
            return __assign(__assign({}, notification), { read: true });
        });
        _setNotificationData(__assign(__assign({}, _notificationData), { notifications: notifications }));
    };
    /**
     * update the notification ( message ) as read when click on sidebar notification
     * @param messageId
     */
    var _updateMessageAsRead = function (messageId) {
        var messageList = __spreadArrays(_notificationData.notifications);
        var messageIndex = messageList.findIndex(function (message) { return message.id === messageId; });
        messageList[messageIndex].read = true;
        _setNotificationData(__assign(__assign({}, _notificationData), { notifications: messageList }));
    };
    var _updateMessageAsAcceptedOrDeclined = function (messageId, studentResponse) {
        var messageList = __spreadArrays(_notificationData.notifications);
        var messageIndex = messageList.findIndex(function (message) { return message.id === messageId; });
        messageList[messageIndex].studentResponse = studentResponse;
        _setNotificationData(__assign(__assign({}, _notificationData), { notifications: messageList }));
    };
    var updateMessageAsAcceptedOrDeclined = function (messageId, studentResponse) {
        _updateMessageAsAcceptedOrDeclined(messageId, studentResponse);
    };
    var handleOnTogglePopupNotification = function (message) {
        deletePopupNotification(message.id);
    };
    var handleOnClickPopupNotification = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        var announcementNotification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    deletePopupNotification(message.id);
                    announcementNotification = getAnnouncementNotification(message);
                    if (!announcementNotification) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleOnClickNotification(announcementNotification)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, handleOnClickNotification(message)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    _setNotificationData(__assign(__assign({}, _notificationData), { reFetch: !_notificationData.reFetch }));
                    return [2 /*return*/];
            }
        });
    }); };
    var getAnnouncementNotification = function (message) {
        var messageIndex = _notificationData.notifications.findIndex(function (notification) { return message.id === notification.announcementId; });
        if (messageIndex > -1) {
            return _notificationData.notifications[messageIndex];
        }
        return null;
    };
    var handleOnClickSeeMore = function () {
        _setNotificationData(__assign(__assign({}, _notificationData), { page: _notificationData.page + 1, loading: true }));
    };
    var handleOnMarkAllAsRead = function () {
        _setNotificationBadgeCount(0);
        updateNotificationsMarkRead();
        markAllAsRead();
    };
    return (React.createElement(NotificationContext.Provider, { value: {
            popupNotifications: _popupNotifications,
            activeNotification: activeNotification,
            showNotificationDetail: _showNotificationDetail,
            showSideNotification: showSideNotification,
            dateNotifications: _dateNotifications,
            notificationData: _notificationData,
            notificationBadgeCount: _notificationBadgeCount,
            setShowSideNotification: setShowSideNotification,
            handleOnTogglePopupNotification: handleOnTogglePopupNotification,
            handleOnClickPopupNotification: handleOnClickPopupNotification,
            setShowNotificationDetail: _setShowNotificationDetail,
            toggleSideNotification: toggleSideNotification,
            handleOnClickNotification: handleOnClickNotification,
            handleOnClickSeeMore: handleOnClickSeeMore,
            handleOnMarkAllAsRead: handleOnMarkAllAsRead,
            handleOnMarkAsRead: _OnClickRedirectNotification,
            updateMessageAsAcceptedOrDeclined: updateMessageAsAcceptedOrDeclined,
        } }, props.children));
};
export default NotificationProvider;
