import { terminology, pluralize } from '@fuse/terminology';

export const PROGRAMS_SETUP_ROUTE = `/${pluralize(terminology.program).toLowerCase().replace(' ', '-')}`;
export const PROGRAMS_SETUP_LIST_ROUTE = `${PROGRAMS_SETUP_ROUTE}/list`;
export const DEPARTMENT_SETUP_ROUTE = `/departments`;
export const ADD_PROGRAMS_SETUP_ROUTE = `${PROGRAMS_SETUP_ROUTE}/add`;
export const EDIT_PROGRAMS_SETUP_ROUTE = `${PROGRAMS_SETUP_ROUTE}/:programId/edit`;

const setupProgramsRoutes = {
  root: PROGRAMS_SETUP_ROUTE,
  program: PROGRAMS_SETUP_LIST_ROUTE,
  add: ADD_PROGRAMS_SETUP_ROUTE,
  edit: EDIT_PROGRAMS_SETUP_ROUTE,
};

export default setupProgramsRoutes;
