var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'react-final-form';
export var FinalDatePicker = function (props) {
    var legend = props.legend, name = props.name, required = props.required, format = props.format, formProps = props.formProps, dateRangeValidate = props.dateRangeValidate, isCaret = props.isCaret, showTimeSelect = props.showTimeSelect, showTimeSelectOnly = props.showTimeSelectOnly, timeIntervals = props.timeIntervals, timeCaption = props.timeCaption, minTime = props.minTime, maxTime = props.maxTime, maxDate = props.maxDate, minDate = props.minDate, readOnly = props.readOnly, disabled = props.disabled, placeholder = props.placeholder, fieldArrayVal = props.fieldArrayVal, autoComplete = props.autoComplete, errorClassName = props.errorClassName;
    var isRequired = function (value) { return (value && value.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { name: name, validate: dateRangeValidate ? dateRangeValidate : !required ? undefined : isRequired }, function (_a) {
        var _b;
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: "form-group " + (meta.error && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                required || dateRangeValidate ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", { className: "" + (isCaret ? 'search-status' : '') },
                React.createElement(ReactDatePicker, __assign({}, input, { name: name, dateFormat: format, selected: fieldArrayVal
                        ? new Date(fieldArrayVal)
                        : ((_b = formProps === null || formProps === void 0 ? void 0 : formProps.values) === null || _b === void 0 ? void 0 : _b[name]) ? formProps.values[name]
                            : new Date(), onChange: function (date, e) {
                        if (props === null || props === void 0 ? void 0 : props.onChangeDate) {
                            props.onChangeDate(date, e);
                        }
                        (formProps === null || formProps === void 0 ? void 0 : formProps.form) && formProps.form.change(name, date);
                    }, className: "form-control", showTimeSelect: showTimeSelect, showTimeSelectOnly: showTimeSelectOnly, timeIntervals: timeIntervals, timeCaption: timeCaption, minTime: minTime, maxTime: maxTime, maxDate: maxDate, minDate: minDate, disabled: disabled, readOnly: readOnly, placeholderText: placeholder, autoComplete: autoComplete })),
                (meta === null || meta === void 0 ? void 0 : meta.error) && (meta === null || meta === void 0 ? void 0 : meta.touched) && (React.createElement("span", { className: errorClassName ? errorClassName : 'required' }, meta === null || meta === void 0 ? void 0 : meta.error)))));
    }));
};
