export interface AnalyticsStoreStateType {
  //instructor dashboard
  publishedCourse: string;
  draftCourse: string;
  inactiveStudents: string;
  activeStudents: string;
  coursesViewedToday: string;
  coursesViewThisWeek: string;
  totalStudents: string;
  totalNewStudents: string;
  totalHoursViewed: string;
  averageHoursViewed: string;
  totalCourses: string;
  averageCompletion: string;
  courseHoursMetrics: Array<any>;
  courseStudentHoursMetrics: Array<any>;
  courseNameIdMap: Array<any>;
  timeFrame: string;

  //student dashboard
  coursesEnrolled: number;
  coursesCompleted: number;
  coursesRequested: number;
  coursesInprogress: number;
  courseStatuses: any;
}

export const AnalyticsStoreState: AnalyticsStoreStateType = {
  publishedCourse: '',
  draftCourse: '',
  inactiveStudents: '',
  activeStudents: '',
  coursesViewedToday: '',
  coursesViewThisWeek: '',
  totalStudents: '',
  totalNewStudents: '',
  totalHoursViewed: '',
  averageHoursViewed: '',
  totalCourses: '',
  averageCompletion: '',
  courseHoursMetrics: [],
  courseStudentHoursMetrics: [],
  courseNameIdMap: [],
  timeFrame: '',

  //student dashboard
  coursesEnrolled: 0,
  coursesCompleted: 0,
  coursesRequested: 0,
  coursesInprogress: 0,
  courseStatuses: null,
};
