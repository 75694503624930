import { CourseAnalyticsState } from './state';
import * as types from './types';

export default function reducer(state = CourseAnalyticsState, action: any) {
  switch (action.type) {
    case types.COURSEANALYTICS_INFO:
    case types.COURSEANALYTICS_INFO_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    /* case types.COURSEANALYTICS_INFO_ERROR:
      return {
        ...state,
        ...action.payload,
      }; */

    default:
      return state;
  }
}
