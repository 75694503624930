import { asyncPromiseDataFunc } from '../Axios';
export var getAdminAnnouncements = function (params) {
    return asyncPromiseDataFunc({
        method: 'GET',
        url: "api/v2/admin/announcements",
        params: params,
    });
};
export var createAdminAnnouncement = function (announcementFormData) {
    var message = announcementFormData.message, files = announcementFormData.files, published = announcementFormData.published, receiver = announcementFormData.receiver, tag = announcementFormData.tag, title = announcementFormData.title, messageId = announcementFormData.messageId, sendNotification = announcementFormData.sendNotification, 
    // newFiles,
    oldFiles = announcementFormData.oldFiles, multiProgramLevelIds = announcementFormData.multiProgramLevelIds;
    var formData = new FormData();
    if (message) {
        formData.append('message', message);
    }
    if (published) {
        formData.append('published', "" + published);
    }
    if (receiver) {
        formData.append('receiver', receiver);
    }
    if (tag) {
        formData.append('tag', tag);
    }
    if (title) {
        formData.append('title', title);
    }
    if (messageId) {
        formData.append('messageId', messageId);
    }
    if (sendNotification) {
        formData.append('sendNotification', "" + sendNotification);
    }
    if (files) {
        files.forEach(function (file) {
            formData.append('files', file.file);
        });
    }
    if (oldFiles) {
        var oldFileUrls = oldFiles.map(function (oldFile) {
            return oldFile.url;
        });
        formData.append('oldFiles', oldFileUrls.join(','));
    }
    if (multiProgramLevelIds) {
        formData.append('multiProgramLevelIds', JSON.stringify(multiProgramLevelIds));
    }
    // if (newFiles) {
    //   newFiles.forEach((file: any) => {
    //     formData.append('files', file.file);
    //   });
    // }
    return asyncPromiseDataFunc({
        method: 'POST',
        url: "api/v2/admin/announcements",
        data: formData,
    });
};
export var deleteAdminAnnouncement = function (announcementId, sendNotification) {
    if (sendNotification === void 0) { sendNotification = false; }
    return asyncPromiseDataFunc({
        method: 'DELETE',
        url: "/api/v2/admin/announcements/" + announcementId + "?sendNotification=" + sendNotification,
    });
};
export var publishAmdinDraftedAnnouncement = function (announcementId) {
    return asyncPromiseDataFunc({
        method: 'POST',
        url: "/api/v2/admin/announcements/" + announcementId + "/publish",
    });
};
export var getInstructorAnnouncements = function (params) {
    return asyncPromiseDataFunc({
        method: 'POST',
        url: "/announcements/messages/instructors",
        data: params,
    });
};
