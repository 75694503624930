import React from 'react';
import { isFuseUpskill } from '@fuse/terminology';
var EmptyAssignments = function (props) {
    var title = props.title, subtitle = props.subtitle;
    return (React.createElement("div", { className: "container-fluid empty-state-wrapper" },
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "255.092", height: "195.81", viewBox: "0 0 255.092 195.81" },
            React.createElement("g", { id: "assignment", transform: "translate(-555.645 -272)" },
                React.createElement("g", { id: "Group_13717", "data-name": "Group 13717", transform: "translate(421.023 -600.224)" },
                    React.createElement("path", { id: "Path_26190", "data-name": "Path 26190", d: "M357.041,1065.03H344.274a1.5,1.5,0,1,0,0,3h12.767a1.5,1.5,0,0,0,0-3Z", fill: "#182929" }),
                    React.createElement("path", { id: "Path_26191", "data-name": "Path 26191", d: "M241.926,891.854l1.41-3,2.481-5.28h20.4v-5.83a2.522,2.522,0,0,1,2.52-2.52H283.3a2.522,2.522,0,0,1,2.521,2.52v5.83h20.4l2.481,5.28,1.409,3,4.91,10.43h-78Zm95.72,163.38V903.374a14.532,14.532,0,0,0-14.519-14.52H312.016l-3.9-8.28h-19.3v-2.83a5.532,5.532,0,0,0-5.521-5.52h-14.56a5.532,5.532,0,0,0-5.52,5.52v2.83h-19.3l-3.89,8.28h-11.12a14.538,14.538,0,0,0-14.52,14.52v103.61a31.846,31.846,0,0,0-8.319-2.72v-5.43H210.6v-8.75h-20.49v8.75h4.53v5.43a32.146,32.146,0,0,0-7.85,60.77h-14.13a1.5,1.5,0,0,0,0,3H335.6a1.5,1.5,0,0,0,0-3h-2.529A12.753,12.753,0,0,0,337.646,1055.234Zm-144.54-59.4v-2.75H207.6v2.75Zm9.961,3v5.03c-.9-.07-1.8-.11-2.721-.11s-1.82.04-2.71.11v-5.03Zm-2.861,66.2a29.173,29.173,0,1,1,17.921-6.07,27.491,27.491,0,0,1-2.511,1.74,29.053,29.053,0,0,1-15.169,4.33Zm13.7,0a31.847,31.847,0,0,0,3.3-1.79,12.952,12.952,0,0,0,1.76,1.79Zm111.02,0h-97.8a9.812,9.812,0,0,1-7.43-3.49,32.118,32.118,0,0,0-2.311-52.89V903.374a11.536,11.536,0,0,1,11.52-11.52h9.71l-6.32,13.43h87.45l-6.32-13.43h9.7a11.536,11.536,0,0,1,11.519,11.52v151.86A9.806,9.806,0,0,1,324.926,1065.034Z", fill: "#182929" }),
                    React.createElement("path", { id: "Path_26192", "data-name": "Path 26192", d: "M203.067,998.834v5.03c-.9-.07-1.8-.11-2.721-.11s-1.82.04-2.71.11v-5.03Z", fill: isFuseUpskill ? '#F71735' : '#ff7775' }),
                    React.createElement("rect", { id: "Rectangle_4724", "data-name": "Rectangle 4724", width: "14.49", height: "2.75", transform: "translate(193.106 993.084)", fill: isFuseUpskill ? '#F71735' : '#ff7775' }),
                    React.createElement("path", { id: "Path_26193", "data-name": "Path 26193", d: "M217.386,1012.274a29.131,29.131,0,1,0-17.18,52.76h.241a29.053,29.053,0,0,0,15.169-4.33,27.491,27.491,0,0,0,2.511-1.74,29.105,29.105,0,0,0-.741-46.69Zm.08,44.17a27.623,27.623,0,0,1-2.69,1.96,26.748,26.748,0,1,1-.39-45.27,27.546,27.546,0,0,1,3,2.15,26.735,26.735,0,0,1,.08,41.16Z", fill: isFuseUpskill ? '#F71735' : '#ff7775' }),
                    React.createElement("path", { id: "Path_26194", "data-name": "Path 26194", d: "M323.127,891.854h-9.7l6.32,13.43H232.3l6.32-13.43h-9.71a11.536,11.536,0,0,0-11.52,11.52v105.28a32.118,32.118,0,0,1,2.311,52.89,9.812,9.812,0,0,0,7.43,3.49h97.8a9.806,9.806,0,0,0,9.72-9.8V903.374A11.536,11.536,0,0,0,323.127,891.854Zm-6.261,133.99h-81.7v-5.45h81.7Zm0-20.43h-81.7v-5.44h81.7Zm0-20.42h-81.7v-5.45h81.7Zm0-20.43h-81.7v-5.44h81.7Zm0-20.42h-81.7v-5.45h81.7Z", fill: isFuseUpskill ? '#FEE8EB' : '#a19bfd', opacity: "0.1", style: { isolation: 'isolate' } }),
                    React.createElement("path", { id: "Path_26195", "data-name": "Path 26195", d: "M315.016,902.284h-78l4.91-10.43,1.41-3,2.481-5.28h20.4v-5.83a2.522,2.522,0,0,1,2.52-2.52H283.3a2.522,2.522,0,0,1,2.521,2.52v5.83h20.4l2.481,5.28,1.409,3Z", fill: isFuseUpskill ? '#FFCB47' : '#fdc96e' }),
                    React.createElement("path", { id: "Path_26196", "data-name": "Path 26196", d: "M217.386,1015.284a27.546,27.546,0,0,0-3-2.15,26.752,26.752,0,1,0,.39,45.27,27.623,27.623,0,0,0,2.69-1.96,26.735,26.735,0,0,0-.08-41.16Zm0,37.13a22.617,22.617,0,0,1-3,2.62,23.745,23.745,0,1,1,0-38.29,24.165,24.165,0,0,1,3,2.62,23.7,23.7,0,0,1,0,33.05Z", fill: "#182929" }),
                    React.createElement("path", { id: "Path_26197", "data-name": "Path 26197", d: "M217.386,1019.364a24.165,24.165,0,0,0-3-2.62,23.755,23.755,0,1,0,0,38.29,22.617,22.617,0,0,0,3-2.62,23.7,23.7,0,0,0,0-33.05Zm-17.04,20.85a2.124,2.124,0,0,1-2.11-2.31l1.55-17.19a.568.568,0,0,1,1.13,0l1.54,17.19A2.112,2.112,0,0,1,200.346,1040.214Z", fill: isFuseUpskill ? '#FEE8EB' : '#a19bfd', opacity: "0.1", style: { isolation: 'isolate' } }),
                    React.createElement("path", { id: "Path_26198", "data-name": "Path 26198", d: "M202.456,1037.9a2.119,2.119,0,1,1-4.22,0l1.55-17.19a.568.568,0,0,1,1.13,0Z", fill: "#182929" }),
                    React.createElement("rect", { id: "Rectangle_4725", "data-name": "Rectangle 4725", width: "81.7", height: "5.45", transform: "translate(235.166 938.694)", fill: isFuseUpskill ? '#6D49B1' : '#00b694' }),
                    React.createElement("rect", { id: "Rectangle_4726", "data-name": "Rectangle 4726", width: "81.7", height: "5.44", transform: "translate(235.166 959.124)", fill: isFuseUpskill ? '#6D49B1' : '#00b694' }),
                    React.createElement("rect", { id: "Rectangle_4727", "data-name": "Rectangle 4727", width: "81.7", height: "5.45", transform: "translate(235.166 979.544)", fill: isFuseUpskill ? '#6D49B1' : '#00b694' }),
                    React.createElement("rect", { id: "Rectangle_4728", "data-name": "Rectangle 4728", width: "81.7", height: "5.44", transform: "translate(235.166 999.974)", fill: isFuseUpskill ? '#6D49B1' : '#00b694' }),
                    React.createElement("rect", { id: "Rectangle_4729", "data-name": "Rectangle 4729", width: "81.7", height: "5.45", transform: "translate(235.166 1020.394)", fill: isFuseUpskill ? '#6D49B1' : '#00b694' })),
                React.createElement("path", { id: "Path_26199", "data-name": "Path 26199", d: "M188.909,961.235h-2.432V958.8a.609.609,0,1,0-1.218,0v2.432h-2.432a.609.609,0,0,0,0,1.218h2.432v2.432a.609.609,0,1,0,1.218,0v-2.432h2.432a.609.609,0,0,0,0-1.218Z", transform: "translate(421.023 -600.224)", fill: isFuseUpskill ? '#F71735' : '#ff7775' }),
                React.createElement("path", { id: "Path_26200", "data-name": "Path 26200", d: "M387.394,960.2l-6.806,2.13-2.13-6.806a1.786,1.786,0,1,0-3.409,1.067l2.13,6.806-6.8,2.13a1.786,1.786,0,0,0,1.067,3.409l6.8-2.13,2.13,6.8a1.786,1.786,0,1,0,3.409-1.067l-2.13-6.8,6.806-2.13a1.786,1.786,0,0,0-1.067-3.409Z", transform: "translate(421.023 -600.224)", fill: isFuseUpskill ? '#FEE8EB' : '#a19bfd', opacity: "0.1", style: { isolation: 'isolate' } }),
                React.createElement("g", { id: "Group_13718", "data-name": "Group 13718", transform: "translate(421.023 -600.224)", opacity: "0.5", style: { isolation: 'isolate' } },
                    React.createElement("path", { id: "Path_26201", "data-name": "Path 26201", d: "M371.894,895.4a4.328,4.328,0,0,1-.529-2.544c.014-.924-.02-1.3-.445-1.651a1.044,1.044,0,1,1,1.322-1.616,3.727,3.727,0,0,1,1.21,3.3c-.014.923.02,1.3.445,1.651s.8.3,1.708.109a3.456,3.456,0,0,1,4.684,3.832c-.013.922.023,1.306.446,1.651a1.044,1.044,0,1,1-1.321,1.617,3.722,3.722,0,0,1-1.211-3.3c.013-.923-.022-1.307-.447-1.651s-.805-.306-1.708-.11a3.724,3.724,0,0,1-3.473-.534A3.238,3.238,0,0,1,371.894,895.4Z", fill: "#fdc96e" })),
                React.createElement("g", { id: "Group_13719", "data-name": "Group 13719", transform: "translate(421.023 -600.224)", opacity: "0.1", style: { isolation: 'isolate' } },
                    React.createElement("path", { id: "Path_26202", "data-name": "Path 26202", d: "M141.628,960.867a7,7,0,1,0,6.988,7.006A7.016,7.016,0,0,0,141.628,960.867Zm0,10.93a3.924,3.924,0,1,1,3.924-3.924A3.934,3.934,0,0,1,141.628,971.8Z", fill: isFuseUpskill ? '#FEE8EB' : '#a19bfd' })),
                React.createElement("g", { id: "Group_13720", "data-name": "Group 13720", transform: "translate(421.023 -600.224)", opacity: "0.5", style: { isolation: 'isolate' } },
                    React.createElement("path", { id: "Path_26203", "data-name": "Path 26203", d: "M380.633,1016.441a7,7,0,1,0,8.761,4.6A7.016,7.016,0,0,0,380.633,1016.441Zm3.264,10.431a3.924,3.924,0,1,1,2.573-4.917A3.933,3.933,0,0,1,383.9,1026.872Z", fill: isFuseUpskill ? '#F71735' : '#ff7775' })),
                React.createElement("g", { id: "Group_13721", "data-name": "Group 13721", transform: "translate(421.023 -600.224)", opacity: "0.5", style: { isolation: 'isolate' } },
                    React.createElement("path", { id: "Path_26204", "data-name": "Path 26204", d: "M183.592,895.329a1.08,1.08,0,0,1-1.8-.368,3.374,3.374,0,0,1,.857-3.287c.52-.7.665-.962.557-1.258s-.388-.408-1.236-.611a3,3,0,0,1-1.906-5.261c.52-.7.664-.963.557-1.261a1.079,1.079,0,1,1,2.029-.736,3.377,3.377,0,0,1-.856,3.287c-.52.7-.664.963-.557,1.26s.389.409,1.236.611a2.995,2.995,0,0,1,1.906,5.26c-.52.7-.665.962-.557,1.259A1.081,1.081,0,0,1,183.592,895.329Z", fill: "#74b8ff" })))),
        title && React.createElement("p", { className: "empty-state-title" }, title),
        subtitle && React.createElement("p", { className: "empty-state-info" }, subtitle)));
};
export default EmptyAssignments;
