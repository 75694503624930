import { terminology } from '@fuse/terminology';

export const SCHOOL_ROUTE = `/${terminology.school.toLowerCase()}`;
export const ADD_SCHOOL_ROUTE = `${SCHOOL_ROUTE}/add`;
export const LIST_SCHOOL_ROUTE = `${SCHOOL_ROUTE}/list`;
export const EDIT_SCHOOL_ROUTE = `${SCHOOL_ROUTE}/edit/:schoolId`;

const schoolRoutes = {
  root: SCHOOL_ROUTE,
  add: ADD_SCHOOL_ROUTE,
  list: LIST_SCHOOL_ROUTE,
  edit: EDIT_SCHOOL_ROUTE,
};

export default schoolRoutes;
