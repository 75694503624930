var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalCheckbox = function (props) {
    var legend = props.legend, name = props.name, className = props.className, id = props.id, disabled = props.disabled, checkboxWrapperClassName = props.checkboxWrapperClassName, hideExtraLabel = props.hideExtraLabel;
    return (React.createElement(Field, { name: name, type: "checkbox" }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: (className || 'form-group') + " " + (meta.touched && meta.error ? 'error' : '') },
            !hideExtraLabel && React.createElement("label", { htmlFor: id || name }),
            React.createElement("div", { className: checkboxWrapperClassName || 'custom-checkbox mt-2' },
                React.createElement("input", __assign({}, input, { id: id ? id : name, type: "checkbox", disabled: disabled, onChange: function (e) {
                        if (props === null || props === void 0 ? void 0 : props.onChange) {
                            props.onChange(e);
                        }
                        input.onChange(e);
                    } })),
                React.createElement("label", { htmlFor: id || name, className: "pointer" },
                    React.createElement("span", null, legend)),
                meta.error && meta.touched && React.createElement("span", { className: "required" }, meta.error))));
    }));
};
