import React from 'react';
var SelectFilterToViewSVG = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "228.841", height: "284.995", viewBox: "0 0 228.841 284.995" },
        React.createElement("g", { id: "analytics-empty", transform: "translate(-446.159 -114.152)" },
            React.createElement("text", { id: "Select_filters_to_view_graph_", "data-name": "Select filters to view graph ", transform: "translate(459.802 392.147)", fontSize: "18", fontFamily: "SourceSansPro-Semibold, Source Sans Pro", fontWeight: "600", style: { isolation: 'isolate' } },
                React.createElement("tspan", { x: "0", y: "0" },
                    "Select filters to view graph",
                    ' ')),
            React.createElement("g", { id: "Group_14296-2", "data-name": "Group 14296-2" },
                React.createElement("path", { id: "Path_26817", "data-name": "Path 26817", d: "M627.471,346.423H471.237a1.262,1.262,0,1,1,0-2.513H627.471a1.262,1.262,0,1,1,0,2.513Z", fill: "#182929" })),
            React.createElement("g", { id: "Group_14297", "data-name": "Group 14297" },
                React.createElement("path", { id: "Path_26818", "data-name": "Path 26818", d: "M644.443,346.423H633.751a1.257,1.257,0,0,1,0-2.513h10.692a1.257,1.257,0,1,1,0,2.513Z", fill: "#182929" })),
            React.createElement("path", { id: "Path_26837", "data-name": "Path 26837", d: "M464.365,218.172h-3.65v-3.649a.914.914,0,0,0-1.827-.061c0,.02,0,.041,0,.061v3.649h-3.65a.914.914,0,0,0,0,1.828h3.65v3.649a.914.914,0,0,0,1.827.06V220h3.65a.914.914,0,1,0,0-1.828Z", fill: "#a29cfe", opacity: "0.5", style: { isolation: 'isolate' } }),
            React.createElement("path", { id: "Path_26838", "data-name": "Path 26838", d: "M461.574,298.253l-5.7,1.784-1.784-5.7a1.5,1.5,0,0,0-2.855.894l1.784,5.7-5.7,1.784a1.5,1.5,0,0,0,.681,2.921,1.593,1.593,0,0,0,.213-.066l5.7-1.784,1.784,5.7a1.5,1.5,0,0,0,2.921-.681,1.421,1.421,0,0,0-.066-.213l-1.784-5.7,5.7-1.784a1.5,1.5,0,0,0-.894-2.854Z", fill: "#a29cfe", opacity: "0.1", style: { isolation: 'isolate' } }),
            React.createElement("path", { id: "Path_26839", "data-name": "Path 26839", d: "M478,119.015l-5.7,1.784-1.784-5.7a1.5,1.5,0,0,0-2.855.894l1.784,5.7-5.7,1.784a1.5,1.5,0,0,0,.894,2.855l5.7-1.784,1.784,5.7a1.5,1.5,0,0,0,2.922-.681,1.548,1.548,0,0,0-.067-.213l-1.784-5.7,5.7-1.784a1.5,1.5,0,0,0-.894-2.854Z", fill: "#a29cfe", opacity: "0.1", style: { isolation: 'isolate' } }),
            React.createElement("g", { id: "Group_14321", "data-name": "Group 14321", opacity: "0.5" },
                React.createElement("path", { id: "Path_26840", "data-name": "Path 26840", d: "M645.989,141.841a3.621,3.621,0,0,1-.443-2.131c.012-.773-.017-1.093-.373-1.383a.874.874,0,0,1,1.107-1.353h0a3.125,3.125,0,0,1,1.013,2.762c-.012.774.017,1.092.373,1.383s.674.256,1.43.092a2.894,2.894,0,0,1,3.923,3.209c-.011.772.019,1.094.374,1.383a.874.874,0,1,1-1.106,1.354,3.118,3.118,0,0,1-1.014-2.764c.011-.772-.018-1.1-.374-1.383s-.674-.256-1.43-.092a3.118,3.118,0,0,1-2.909-.446A2.724,2.724,0,0,1,645.989,141.841Z", fill: "#feca6e" })),
            React.createElement("g", { id: "Group_14322", "data-name": "Group 14322", opacity: "0.1" },
                React.createElement("path", { id: "Path_26841", "data-name": "Path 26841", d: "M664.814,225.28A10.2,10.2,0,1,0,675,235.494v0A10.226,10.226,0,0,0,664.814,225.28Zm0,15.932a5.72,5.72,0,1,1,5.72-5.72h0A5.736,5.736,0,0,1,664.814,241.212Z", fill: "#a29cfe" })),
            React.createElement("g", { id: "Group_14323", "data-name": "Group 14323", opacity: "0.5" },
                React.createElement("path", { id: "Path_26842", "data-name": "Path 26842", d: "M663.22,304.73a5.86,5.86,0,1,0,7.338,3.852A5.861,5.861,0,0,0,663.22,304.73Zm2.734,8.736a3.286,3.286,0,1,1,2.155-4.118,3.286,3.286,0,0,1-2.155,4.118Z", fill: "#ff7775" })),
            React.createElement("g", { id: "Group_16007", "data-name": "Group 16007" },
                React.createElement("g", { id: "Group_16002", "data-name": "Group 16002" },
                    React.createElement("rect", { id: "Rectangle_5701", "data-name": "Rectangle 5701", width: "122.915", height: "192.812", rx: "8.74", transform: "translate(487.896 152.052)", fill: "#a19bfd", opacity: "0.1", style: { isolation: 'isolate' } }),
                    React.createElement("rect", { id: "Rectangle_5702", "data-name": "Rectangle 5702", width: "122.915", height: "192.812", rx: "8.74", transform: "translate(487.896 152.052)", fill: "none", stroke: "#000", strokeMiterlimit: "10", strokeWidth: "3", style: { isolation: 'isolate' } })),
                React.createElement("g", { id: "Group_14300", "data-name": "Group 14300" },
                    React.createElement("path", { id: "Path_26821", "data-name": "Path 26821", d: "M517.069,183.9a11.306,11.306,0,1,0,11.307,11.305V195.2A11.315,11.315,0,0,0,517.069,183.9Zm0,20.1a8.794,8.794,0,1,1,8.8-8.8A8.793,8.793,0,0,1,517.069,204Z", fill: "#182929" })),
                React.createElement("line", { id: "Line_730", "data-name": "Line 730", x2: "17", transform: "translate(553.874 191.5)", fill: "none", stroke: "#182929", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "5" }),
                React.createElement("g", { id: "Group_14301", "data-name": "Group 14301" },
                    React.createElement("path", { id: "Path_26822", "data-name": "Path 26822", d: "M567.431,191.436c0,.693-1.009,1.254-2.251,1.256H538.164c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h27.015C566.421,190.181,567.428,190.743,567.431,191.436Z", fill: "#182929" })),
                React.createElement("g", { id: "Group_14302", "data-name": "Group 14302" },
                    React.createElement("path", { id: "Path_26823", "data-name": "Path 26823", d: "M582.374,198.974c0,.693-.822,1.254-1.833,1.256h-42.8c-1.013,0-1.834-.562-1.834-1.256s.821-1.257,1.834-1.257h42.8C581.553,197.72,582.372,198.281,582.374,198.974Z", fill: "#182929" })),
                React.createElement("g", { id: "Group_16003", "data-name": "Group 16003" },
                    React.createElement("g", { id: "Group_14300-2", "data-name": "Group 14300" },
                        React.createElement("path", { id: "Path_26821-2", "data-name": "Path 26821", d: "M517.069,254.9a11.306,11.306,0,1,0,11.307,11.305V266.2A11.315,11.315,0,0,0,517.069,254.9Zm0,20.1a8.794,8.794,0,1,1,8.8-8.8A8.793,8.793,0,0,1,517.069,275Z", fill: "#182929" })),
                    React.createElement("g", { id: "Group_14303", "data-name": "Group 14303" },
                        React.createElement("g", { id: "Group_14301-2", "data-name": "Group 14301" },
                            React.createElement("path", { id: "Path_26822-2", "data-name": "Path 26822", d: "M567.431,262.436c0,.693-1.009,1.254-2.251,1.256H538.164c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h27.015C566.421,261.181,567.428,261.743,567.431,262.436Z", fill: "#182929" })),
                        React.createElement("g", { id: "Group_14302-2", "data-name": "Group 14302" },
                            React.createElement("path", { id: "Path_26823-2", "data-name": "Path 26823", d: "M592.944,269.974c0,.693-1.009,1.254-2.25,1.256h-52.53c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h52.53C591.936,268.72,592.941,269.281,592.944,269.974Z", fill: "#182929" })))),
                React.createElement("g", { id: "Group_16004", "data-name": "Group 16004" },
                    React.createElement("g", { id: "Group_14300-3", "data-name": "Group 14300" },
                        React.createElement("path", { id: "Path_26821-3", "data-name": "Path 26821", d: "M517.069,290.4a11.306,11.306,0,1,0,11.307,11.3V301.7A11.315,11.315,0,0,0,517.069,290.4Zm0,20.1a8.794,8.794,0,1,1,8.8-8.8A8.793,8.793,0,0,1,517.069,310.5Z", fill: "#182929" })),
                    React.createElement("g", { id: "Group_14303-2", "data-name": "Group 14303" },
                        React.createElement("g", { id: "Group_14301-3", "data-name": "Group 14301" },
                            React.createElement("path", { id: "Path_26822-3", "data-name": "Path 26822", d: "M567.431,297.936c0,.693-1.009,1.254-2.251,1.256H538.164c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h27.015C566.421,296.681,567.428,297.243,567.431,297.936Z", fill: "#182929" })),
                        React.createElement("g", { id: "Group_14302-3", "data-name": "Group 14302" },
                            React.createElement("path", { id: "Path_26823-3", "data-name": "Path 26823", d: "M592.944,305.474c0,.693-1.009,1.254-2.25,1.256h-52.53c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h52.53C591.936,304.22,592.941,304.781,592.944,305.474Z", fill: "#182929" })))),
                React.createElement("g", { id: "Group_16006", "data-name": "Group 16006" },
                    React.createElement("g", { id: "Group_14313", "data-name": "Group 14313" },
                        React.createElement("g", { id: "Group_14311", "data-name": "Group 14311" },
                            React.createElement("path", { id: "Path_26828", "data-name": "Path 26828", d: "M567.513,227.009c0,.693-1.009,1.254-2.25,1.256H538.246c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h27.015C566.5,225.754,567.51,226.316,567.513,227.009Z", fill: "#182929" })),
                        React.createElement("g", { id: "Group_14312", "data-name": "Group 14312" },
                            React.createElement("path", { id: "Path_26829", "data-name": "Path 26829", d: "M593.027,234.547c0,.693-1.01,1.254-2.251,1.256h-52.53c-1.244,0-2.252-.562-2.252-1.256s1.008-1.257,2.252-1.257h52.53C592.018,233.293,593.024,233.854,593.027,234.547Z", fill: "#182929" }))),
                    React.createElement("g", { id: "Group_16005", "data-name": "Group 16005" },
                        React.createElement("g", { id: "Group_14310", "data-name": "Group 14310" },
                            React.createElement("path", { id: "Path_26827", "data-name": "Path 26827", d: "M526.994,225.359a11,11,0,0,0-1.466-2.085,11.352,11.352,0,1,0,1.466,2.085Zm-9.916,14.215a8.794,8.794,0,1,1,6.667-14.514,8.4,8.4,0,0,1,1.373,2.178,8.6,8.6,0,0,1,.754,3.543A8.8,8.8,0,0,1,517.078,239.574Z", fill: "#182929" })),
                        React.createElement("g", { id: "Group_14314", "data-name": "Group 14314" },
                            React.createElement("path", { id: "Path_26830", "data-name": "Path 26830", d: "M530.948,221.406,516.4,235.953l-5.888-3.92a1.259,1.259,0,1,1,1.4-2.094l4.171,2.781,13.09-13.09a1.249,1.249,0,0,1,1.766-.009l.01.009A1.264,1.264,0,0,1,530.948,221.406Z", fill: "#182929" })),
                        React.createElement("path", { id: "Path_26834", "data-name": "Path 26834", d: "M525.872,230.779a8.817,8.817,0,1,1-2.127-5.72l-7.663,7.663-4.171-2.781a1.259,1.259,0,0,0-1.4,2.094h0l5.888,3.92,8.718-8.718A8.614,8.614,0,0,1,525.872,230.779Z", fill: "#00b795" }))),
                React.createElement("line", { id: "Line_731", "data-name": "Line 731", x2: "37", transform: "translate(554.875 198.998)", fill: "none", stroke: "#182929", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "5" })),
            React.createElement("g", { id: "Group_14137", "data-name": "Group 14137" },
                React.createElement("circle", { id: "Ellipse_1354", "data-name": "Ellipse 1354", cx: "27.097", cy: "27.097", r: "27.097", transform: "translate(556.052 164.619)", fill: "#fff", opacity: "0.49", style: { isolation: 'isolate' } }),
                React.createElement("g", { id: "Group_14136", "data-name": "Group 14136" },
                    React.createElement("g", { id: "Group_14134", "data-name": "Group 14134" },
                        React.createElement("rect", { id: "Rectangle_4793", "data-name": "Rectangle 4793", width: "10.015", height: "40.17", rx: "4.369", transform: "translate(606.221 221.572) rotate(-45)", fill: "#ed5e61" }),
                        React.createElement("path", { id: "Path_26651", "data-name": "Path 26651", d: "M634.625,249.845a6.9,6.9,0,0,1-4.919-2.032l-21.323-21.322a6.956,6.956,0,1,1,9.836-9.839l21.325,21.325a6.964,6.964,0,0,1,0,9.836h0A6.909,6.909,0,0,1,634.625,249.845ZM613.3,218.515a3.058,3.058,0,0,0-2.164,5.22l21.324,21.323a3.059,3.059,0,0,0,4.325-4.326l-21.323-21.323a3.049,3.049,0,0,0-2.162-.894Z", fill: "#161514" })),
                    React.createElement("g", { id: "Group_14135", "data-name": "Group 14135" },
                        React.createElement("rect", { id: "Rectangle_4794", "data-name": "Rectangle 4794", width: "20.171", height: "19.046", rx: "7.122", transform: "translate(593.314 215.847) rotate(-45)", fill: "#ed5e61" }),
                        React.createElement("path", { id: "Path_26652", "data-name": "Path 26652", d: "M606.781,227.879a10.076,10.076,0,0,1-7.149-2.957L597.705,223a10.135,10.135,0,0,1,0-14.3l2.722-2.721a10.117,10.117,0,0,1,14.3,0l1.926,1.925a10.122,10.122,0,0,1,0,14.3l-2.721,2.721A10.068,10.068,0,0,1,606.781,227.879Zm.795-20.965a6.192,6.192,0,0,0-4.392,1.816l-2.722,2.722a6.221,6.221,0,0,0,0,8.784l1.927,1.928a6.221,6.221,0,0,0,8.785,0l2.721-2.721a6.219,6.219,0,0,0,0-8.785l-1.926-1.926A6.188,6.188,0,0,0,607.576,206.914Z", fill: "#161514" }))),
                React.createElement("path", { id: "Path_26653", "data-name": "Path 26653", d: "M561.065,169.335a31.749,31.749,0,1,0,44.9,0,31.749,31.749,0,0,0-44.9,0Zm39.477,39.484a24.084,24.084,0,1,1-.006-34.061l.006.007A24.083,24.083,0,0,1,600.542,208.819Z", fill: "#ed5e61" }),
                React.createElement("path", { id: "Path_26654", "data-name": "Path 26654", d: "M583.515,225.469a33.681,33.681,0,1,1,23.828-9.855A33.682,33.682,0,0,1,583.515,225.469Zm0-63.469a29.787,29.787,0,1,0,21.071,8.708A29.787,29.787,0,0,0,583.515,162Zm0,55.8a26.018,26.018,0,1,1,18.4-7.612,26.017,26.017,0,0,1-18.4,7.612Zm0-48.136a22.114,22.114,0,1,0,15.648,6.472,22.115,22.115,0,0,0-15.648-6.472Z", fill: "#161514" })))));
};
export default SelectFilterToViewSVG;
