const HOME = `/setupPrograms`;
export const ASSIGN_COURSES = `${HOME}/assignCourses`;
export const ASSIGN_STUDENTS = `${HOME}/assignStudents`;
export const ASSIGN_INSTRUCTORS = `${HOME}/assignInstructors`;

const setupCoursesRoutes = {
  home: HOME,
  assignCourses: ASSIGN_COURSES,
  assignStudents: ASSIGN_STUDENTS,
  assignInstructors: ASSIGN_INSTRUCTORS,
};

export default setupCoursesRoutes;
