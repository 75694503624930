var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../useAuth';
import { LoaderWrap } from '@fuse/ui-components/dist/LoaderWrap';
import { ROOT_ROUTE } from '../../constants';
export var PrivateRoute = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var isAuthenticated = useAuth().isAuthenticated;
    if (typeof isAuthenticated !== 'boolean') {
        return React.createElement(LoaderWrap, { isLoading: true, type: "Main" });
    }
    return React.createElement(React.Fragment, null, isAuthenticated ? React.createElement(Redirect, { to: ROOT_ROUTE }) : React.createElement(Route, __assign({ component: Component }, rest)));
};
export default PrivateRoute;
