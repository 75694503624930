export enum POST {
  ADD_POST = 'ADD_POST',
  UPDATE_POSTS = 'UPDATE_POSTS',
  UPDATE_POST = 'UPDATE_POST',
  DELETE_POST = 'DELETE_POST',
  ADD_COMMENT = 'ADD_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  GET_ALL_COMMENTS = 'GET_ALL_COMMENTS',
  TOGGLE_LIKE = 'TOGGLE_LIKE',
  FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR',
  CLEAR_ALL = 'CLEAR_ALL',
  CLEAR_ALL_POSTS = 'CLEAR_ALL_POSTS',
  GET_ONE_POST = 'GET_ONE_POST',
  FETCH_POSTS_USER = 'FETCH_POSTS_USER',
  FETCH_POSTS_USER_OTHER = 'FETCH_POSTS_USER_OTHER',
  FETCH_POSTS_USER_ERROR = 'FETCH_POSTS_USER_ERROR',
}
export enum GROUP {
  ADD_GROUP = 'ADD_GROUP',
  ADD_GROUP_ERROR = 'ADD_GROUP_ERROR',
  UPDATE_GROUP = 'UPDATE_GROUP',
  UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR',
  FETCH_ALL_GROUP = 'FETCH_ALL_GROUP',
  FETCH_ALL_GROUP_ERROR = 'FETCH_ALL_GROUP_ERROR',
  FETCH_GROUP_BY_ID = 'FETCH_GROUP_BY_ID',
  FETCH_GROUP_BY_ID_ERROR = 'FETCH_GROUP_BY_ID_ERROR',
  UPLOAD_GROUP_IMAGE = 'UPLOAD_GROUP_IMAGE',
  UPDATE_GROUP_VISIBILITY = 'UPDATE_GROUP_VISIBILITY',
  UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME',
  GET_GROUP_MEMBERS_BY_ID = 'GET_GROUP_MEMBERS_BY_ID',
  ADD_GROUP_MEMBERS_BY_ID = 'ADD_GROUP_MEMBERS_BY_ID',
  REMOVE_GROUP_MEMBERS_BY_IDS = 'REMOVE_GROUP_MEMBERS_BY_IDS',
  TOGGLE_SHOW_ALL_GROUP = 'TOGGLE_SHOW_ALL_GROUP',
}
export enum FILE {
  LIST_FILES = 'LIST_FILES',
  CLEAR_FILES = 'CLEAR_FILES',
}
export enum ALL {
  CLEAR_ALL = 'CLEAR_ALL',
  TOGGLESIDEBAR = 'TOGGLESIDEBAR',
}
export enum CARDVIEWMODAL {
  TOGGLE_CARD_VIEW = 'TOGGLE_CARD_VIEW',
}
enum BIO {
  BIO_WORKING = 'BIO_WORKING',
  BIO_CACHE = 'BIO_CACHE',
  BIO_ERROR = 'BIO_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum CERTIFICATIONS {
  CERTIFICATIONS_WORKING = 'CERTIFICATIONS_WORKING',
  CERTIFICATIONS_CACHE = 'CERTIFICATIONS_CACHE',
  CERTIFICATIONS_ADD = 'CERTIFICATIONS_ADD',
  CERTIFICATIONS_ERROR = 'CERTIFICATIONS_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum EDUCATION {
  EDUCATION_WORKING = 'EDUCATION_WORKING',
  EDUCATION_CACHE = 'EDUCATION_CACHE',
  EDUCATION_ADD = 'EDUCATION_ADD',
  EDUCATION_ERROR = 'EDUCATION_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum PROJECTS {
  PROJECTS_WORKING = 'PROJECTS_WORKING',
  PROJECTS_CACHE = 'PROJECTS_CACHE',
  PROJECTS_ADD = 'PROJECTS_ADD',
  PROJECTS_ERROR = 'PROJECTS_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum SKILLS {
  SKILLS_WORKING = 'SKILLS_WORKING',
  SKILLS_CACHE = 'SKILLS_CACHE',
  SKILLS_ADD = 'SKILLS_ADD',
  SKILLS_ERROR = 'SKILLS_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum STATUS {
  PROFILE_WORKING = 'PROFILE_WORKING',
  PROFILE_DONE = 'PROFILE_DONE',
  PROFILE_ERROR = 'PROFILE_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}
enum WORK_EXPERIENCE {
  WORK_EXPERIENCE_WORKING = 'WORK_EXPERIENCE_WORKING',
  WORK_EXPERIENCE_CACHE = 'WORK_EXPERIENCE_CACHE',
  WORK_EXPERIENCE_ADD = 'WORK_EXPERIENCE_ADD',
  WORK_EXPERIENCE_ERROR = 'WORK_EXPERIENCE_ERROR',
  PROFILE_INVALIDATE = 'PROFILE_INVALIDATE',
}

export const PROFILE = {
  BIO: BIO,
  CERTIFICATIONS: CERTIFICATIONS,
  EDUCATION: EDUCATION,
  PROJECTS: PROJECTS,
  SKILLS: SKILLS,
  STATUS: STATUS,
  WORK_EXPERIENCE: WORK_EXPERIENCE,
  PROFILE_INVALIDATE: 'PROFILE_INVALIDATE',
};

export enum PROJECT {
  FETCH_PROJECTS = 'FETCH_PROJECTS',
  FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR',
  UPDATE_PROFILE_TEAM = 'UPDATE_PROFILE_TEAM',
  CLEAR_ALL = 'CLEAR_ALL',
  CHANGE_CURRENT_PROJECT = 'CHANGE_CURRENT_PROJECT',
  LEAVE_PROJECT = 'LEAVE_PROJECT',
}

export enum SESSION {
  RELOAD_AUTH = 'RELOAD_AUTH',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAIL = 'LOG_IN_FAIL',
  LOG_OUT = 'LOG_OUT',
  PROFILE = 'PROFILE',
  PROFILE_WORKING = 'PROFILE_WORKING',
  PROFILE_ERROR = 'PROFILE_ERROR',
  PROFILE_PIC = 'PROFILE_PIC',
  PROFILE_LOADING = 'PROFILE_LOADING',
}

export enum NOTIFICATION {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  MARK_READ_NOTIFICATION = 'MARK_READ_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  RESET_NOTIFICATION_COUNTER = 'RESET_NOTIFICATION_COUNTER',
  NOTIFICATION_MARK_AS_READ = 'NOTIFICATION_MARK_AS_READ',
  NOTIFICATION_MARK_ALL_AS_READ = 'NOTIFICATION_MARK_ALL_AS_READ',
}

export enum USER {
  GET_USER = 'GET_USER',
  GET_USER_ERROR = 'GET_USER_ERROR',
  USER_LOADING = 'USER_LOADING',
}
